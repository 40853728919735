import {
  Box,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import ActionButtons from "../buttons/ActionButtons";
import BillPaid from "./BillPaid";
import { styled } from "@mui/material/styles";

const MuiToggleButton = styled(ToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor,
  },
}));

const inputStyle = { display: "flex", flexDirection: "column", gap: "4px" };

const StepThree = ({ partnerId, onClickBack, index, handleReset }) => {
  const [isBillPaid, setIsBillPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    partnerId: "",
    paymentCollectionTimestamp: "",
    amountCollected: "",
    amountCollectedType: "",
  });

  const [amountType, setAmountType] = useState("");
  console.log("amountType: ", amountType);
  console.log("formData: ", formData);

  const handleChangeToggleButton = (event, value) => {
    setAmountType(value);
  };
  useEffect(() => {
    if (amountType) {
      setFormData((prev) => ({ ...prev, amountCollectedType: amountType }));
    } else {
      setFormData((prev) => ({ ...prev, amountCollectedType: "" }));
    }
  }, [amountType]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, partnerId }));
  }, [partnerId]);
  useEffect(() => {
    if (
      selectedDate &&
      new Date(selectedDate) instanceof Date &&
      !isNaN(selectedDate)
    ) {
      setFormData((prev) => ({
        ...prev,
        // eslint-disable-next-line
        ["paymentCollectionTimestamp"]: selectedDate.toISOString(),
      }));
    }
  }, [selectedDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleToBillPay = () => {
    setIsLoading(true);
    axios
      .post(
        `https://zcxfifanvg.execute-api.us-east-1.amazonaws.com/dev/payment/createCashCollectionEntry`,
        {
          ...formData,
          amountCollected: Math.abs(parseInt(formData.amountCollected, 10)),
        },
        {
          headers: {
            "x-api-key": "D5xTEWXbE85SBhH23736K9O07LoYsKo52PBERgGV",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.billStatus === "paid") {
          setIsBillPaid(true);
        }
        console.log(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isPositiveInteger =
    /^\d+$/.test(formData.amountCollected) &&
    parseInt(formData.amountCollected, 10) > 0;

  const isNegativeInteger = parseInt(formData.amountCollected, 10) < 0;

  const isActive =
    amountType === "positive"
      ? !isPositiveInteger
      : !isNegativeInteger ||
        !partnerId ||
        !formData.paymentCollectionTimestamp;

  return (
    <Box
      sx={{
        maxWidth: 500,
        "&.MuiFormControl-root": {
          "&.MuiOutlinedInput-root": {
            maxWidth: "40px",
          },
        },
      }}
    >
      {isBillPaid ? (
        <BillPaid handleReset={handleReset} />
      ) : (
        <Box>
          <Typography variant="body1" sx={{ my: 1, color: "#1976d2" }}>
            Choose cash collection type:
          </Typography>
          <Box>
            <ToggleButtonGroup
              color="primary"
              value={amountType}
              exclusive
              onChange={handleChangeToggleButton}
              aria-label="Platform"
              sx={{ mb: 1 }}
            >
              <MuiToggleButton value="negative" selectedColor="#1976d2">
                Negative
              </MuiToggleButton>
              <MuiToggleButton value="positive" selectedColor="#1976d2">
                Positive
              </MuiToggleButton>
            </ToggleButtonGroup>
            {!amountType ? (
              <Button
                variant="outlined"
                onClick={onClickBack}
                sx={{ mr: 1, ml: 2 }}
              >
                Back
              </Button>
            ) : null}
          </Box>
          {amountType ? (
            <Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <div style={inputStyle}>
                  <label>Partner ID</label>
                  <TextField size="small" disabled value={partnerId} />
                </div>
                <div style={inputStyle}>
                  <label>Cash Collection</label>
                  <TextField
                    size="small"
                    type="number"
                    name="amountCollected"
                    onChange={handleChange}
                  />
                </div>
                <div style={inputStyle}>
                  <label>Collection Time</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DateTimePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        format="DD/MM/YYYY hh:mm A"
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </div>
              </Box>
              <ActionButtons
                isActive={isActive}
                onClickNext={handleToBillPay}
                onClickBack={onClickBack}
                handleReset={handleReset}
                index={index}
                isLoading={isLoading}
              />
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default StepThree;
