import { useEffect, useState } from "react";
import { Table, TableBody, TableContainer, Box } from "@mui/material";
import CRTableRow from "../components/CRTableRow";
import { PAYMENT_TYPE } from "../constant";
import { useStepperContext } from "../StepperContext";

const StepThree = () => {
  const { data, updateData } = useStepperContext();
  const [walletTopUpAmount, setWalletTopUpAmount] = useState(0);
  const [reasonForWalletTopUp, setReasonForWalletTopUp] = useState("");
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    updateData("payableAmount", data.partnerDetails?.totalPendingAmount);
  }, [data.partnerDetails]);

  // const {
  //   data: partnerBillStatus,
  //   isLoading: isPartnerBillStatusLoading,
  //   refetch: refetchPartnerBillStatus,
  // } = useQuery({
  //   queryKey: ["partnerBillStatus", data.partnerId],
  //   queryFn: () => getPartnerBillStatus(data.partnerId),
  //   enabled: !!data.paymentType === PAYMENT_TYPE.LAST_BILL_AMOUNT,
  // });

  useEffect(() => {
    const amounts = {
      [PAYMENT_TYPE.LAST_BILL_AMOUNT]: data.partnerDetails?.totalPendingAmount,
      [PAYMENT_TYPE.CURRENT_OUTSTANDING_AMOUNT]:
        data.partnerDetails?.outstandingBalance,
    };
    let payableAmount;
    if (data.paymentType === PAYMENT_TYPE.LAST_BILL_AMOUNT)
      payableAmount = amounts[data.paymentType] - discount;
    else if (data.paymentType === PAYMENT_TYPE.CURRENT_OUTSTANDING_AMOUNT)
      payableAmount = amounts[data.paymentType] + discount;
    updateData("payableAmount", payableAmount);
    updateData("discount", discount);
  }, [data.paymentType, discount]);

  useEffect(() => {
    if (data.paymentType === PAYMENT_TYPE.WALLET_TOP_UP_AMOUNT) {
      updateData("walletTopUpAmount", walletTopUpAmount);
      updateData("reasonForWalletTopUp", reasonForWalletTopUp);
    }
  }, [walletTopUpAmount, reasonForWalletTopUp, data.paymentType]);

  const renderTable = (rows) => (
    <TableContainer sx={{ margin: "auto" }}>
      <Table>
        <TableBody>
          {rows && rows.map((row, idx) => <CRTableRow key={idx} {...row} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const commonRows = [
    {
      label: "Discount",
      value: discount,
      isEditable: true,
      onChange: (e) => setDiscount(Number(e.target.value)),
    },
    {
      label: "Amount Paid",
      value: data.payableAmount,
      isValueBold: true,
    },
  ];

  const paymentRows = {
    [PAYMENT_TYPE.LAST_BILL_AMOUNT]: [
      {
        label: "Last Bill Amount",
        value: data?.partnerDetails?.totalPendingAmount,
      },
      ...commonRows,
    ],
    [PAYMENT_TYPE.CURRENT_OUTSTANDING_AMOUNT]: [
      {
        label: "Current Outstanding Amount",
        value: data?.partnerDetails?.outstandingBalance,
      },
      ...commonRows,
    ],
    [PAYMENT_TYPE.WALLET_TOP_UP_AMOUNT]: [
      {
        label: "Wallet Top-Up Amount",
        value: walletTopUpAmount,
        isEditable: true,
        onChange: (e) => setWalletTopUpAmount(Number(e.target.value)),
      },
      {
        label: "Reason",
        type: "text",
        isInputMultiline: true,
        value: reasonForWalletTopUp,
        isEditable: true,
        onChange: (e) => setReasonForWalletTopUp(e.target.value),
      },
    ],
  };

  return (
    <Box mb={4} mt={1}>
      {/* {data.paymentType === PAYMENT_TYPE.LAST_BILL_AMOUNT ? (
        !data.partnerBillStatus ? (
          <CircularProgress />
        ) : data.partnerBillStatus.billStatus === "paid" ? (
          <Box sx={{ width: "100%", m: "auto", textAlign: "center" }}>
            <Typography variant="h6" sx={{ color: "#416D19" }}>
              Bill Already Paid.
            </Typography>
            <Box
              sx={{ maxWidth: 100 }}
              component="img"
              src={billPaidGif}
              alt="Bill Paid"
            />
          </Box>
        ) : (
          renderTable(paymentRows[data.paymentType])
        )
      ) : (
        renderTable(paymentRows[data.paymentType])
      )} */}
      {renderTable(paymentRows[data.paymentType])}
    </Box>
  );
};

export default StepThree;
