import axios from "axios";
import { endpoints } from "../utils/endpoints";

export const getPartnerIds = async () => {
  const { data } = await axios.get(endpoints.partner.ids);
  return data.data;
};

export const getPartnerDetails = async (id) => {
  if (id) {
    const { data } = await axios.get(endpoints.partner.details(id));
    return data.data;
  }
  return {};
};

export const getPartnerBillStatus = async (id) => {
  if (id) {
    const { data } = await axios.get(endpoints.partner.billStatus(id));
    return data;
  }
  return {};
};
