import { Box, Button, CircularProgress } from "@mui/material";

const ActionButtons = ({
  isActive,
  index,
  onClickNext,
  onClickBack,
  isLoading,
  activeStep,
  handleReset,
}) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Button
        variant="contained"
        onClick={onClickNext}
        sx={{ mr: 1, mt: 1, minWidth: 120 }}
        disabled={isActive || isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : index === 2 ? (
          "Submit"
        ) : (
          "Continue"
        )}
      </Button>

      {index === 2 ? (
        <Button variant="outlined" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
          Reset
        </Button>
      ) : (
        <Button
          variant="outlined"
          disabled={index === 0 || activeStep === index + 1}
          onClick={onClickBack}
          sx={{ mr: 1, mt: 1, display: index === 0 ? "none" : "auto" }}
        >
          Back
        </Button>
      )}
    </Box>
  );
};

export default ActionButtons;
