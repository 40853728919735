import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { StepOne, StepThree, StepTwo } from "./steps";
import axios from "axios";
import BillPaid from "./steps/BillPaid";
import { PageTitle } from "../utils";
import PageLayout from "../PageLayout";

const steps = [
  {
    label: "Select Partner ID",
    component: "StepOne",
  },
  {
    label: "Details Of Partner",
    component: "StepTwo",
  },
  {
    label: "Billing",
    component: "StepThree",
  },
];

const CashCollection = () => {
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isBillPaid, setIsBillPaid] = useState(false);

  const handleNext = () => {
    if (activeStep < 4) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setIsBillPaid(false);
    setSelectedPartnerId(null);
  };

  const handleToCheckBilling = () => {
    setIsLoading(true);
    axios
      .request({
        method: "get",
        url: `https://3t7n2z3wci.execute-api.us-east-1.amazonaws.com/dev/api/partners/checkIfBillPaid/${selectedPartnerId?.value}`,
      })
      .then((response) => {
        console.log(response.data.billStatus);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (response.data.billStatus === "paid") {
          setIsBillPaid(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PageLayout>
      <PageTitle title="Cash Collection" />

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} expanded={activeStep >= index}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.component === "StepOne" ? (
                <StepOne
                  selectedItem={selectedPartnerId}
                  onChangePartnerId={setSelectedPartnerId}
                  isActive={selectedPartnerId}
                  onClickNext={handleNext}
                  onClickBack={handleBack}
                  index={index}
                  activeStep={activeStep}
                />
              ) : null}
              {step.component === "StepTwo" ? (
                <StepTwo
                  partnerId={selectedPartnerId?.value}
                  onClickNext={handleToCheckBilling}
                  onClickBack={handleBack}
                  index={index}
                  isLoading={isLoading}
                  isActive={false}
                  activeStep={activeStep}
                />
              ) : null}
              {step.component === "StepThree" && !isBillPaid && (
                <StepThree
                  partnerId={selectedPartnerId?.value}
                  onClickNext={handleNext}
                  onClickBack={handleBack}
                  index={index}
                  isBillPaid={isBillPaid}
                  handleToCheckBilling={handleToCheckBilling}
                  handleReset={handleReset}
                />
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {isBillPaid && <BillPaid handleReset={handleReset} />}
    </PageLayout>
  );
};

export default CashCollection;
