import { useState } from "react";
import axios from "axios";
import { endpoints } from "../../../utils/endpoints";
import { useStepperContext } from "../StepperContext";

const useManualSwap = (onSuccess) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resData, setResData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const { updateData } = useStepperContext();

  const onSubmitManualSwap = (payload) => {
    setIsLoading(true);
    console.log("onSubmitManualSwap", payload);

    let config = {
      method: "post",
      url: endpoints.manualSwap.post,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(payload),
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResData(response.data.message);
        setIsLoading(false);
        if (onSuccess) {
          onSuccess();
        }
        updateData("swapErrorRes", null);
      })
      .catch((error) => {
        console.log(error);
        setErrorMsg(error.message);
        updateData("swapErrorRes", {
          code: error?.response?.status || error?.code,
          message: error?.message,
          // code: error.response.status || error.code,
          // message: error.response.data.message || error.message,
        });
        setIsLoading(false);
      });
  };

  return { isLoading, resData, errorMsg, onSubmitManualSwap };
};

export default useManualSwap;
