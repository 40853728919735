import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useStepperContext } from "../StepperContext";
import InfoCard from "../../manualSwapLatest/InfoCard";
import { dateFormatUtil } from "../../utils";

const ViewCards = ({ view }) => {
  const { data } = useStepperContext();

  const cardConfigs = useMemo(
    () => ({
      partnerDetails: {
        title: "SMB Details",
        fields: {
          "SMB ID": "partnerId",
          "SMB Name": "name",
          "Unpaid Bill Due": "totalPendingAmount",
          "Current Outstanding Amount": "walletBalance",
          "Current Billing Cycle": "currentBillingCycle",
          "Last Payment Date": "lastPaymentDate",
        },
        data: {
          ...data.partnerDetails,
          lastPaymentDate: dateFormatUtil(
            data.partnerDetails?.lastbillPaidDate?._seconds,
            data.partnerDetails?.lastbillPaidDate?._nanoseconds
          ),
        },
      },
    }),
    [data]
  );

  const renderInfoCard = (config) => (
    <InfoCard
      key={config.title}
      data={config.data}
      title={config.title}
      bgcolor="#1DA7A7"
      fieldsToShow={config.fields}
    />
  );

  const renderCard = () => {
    const config = cardConfigs[view];
    return config.component || renderInfoCard(config);
  };

  return <div>{renderCard()}</div>;
};

ViewCards.propTypes = {
  view: PropTypes.oneOf(["partnerDetails"]).isRequired,
};

export default ViewCards;
