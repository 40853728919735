// const baseUrl = "https://prod.baas.batterypool.com";
const baseUrl = "https://test.baas.batterypool.com";

export const endpoints = {
  driver: {
    ids: `${baseUrl}/driver/api/drivers/getListOfDriverIds`,
    details: (driverId, partnerId) =>
      `${baseUrl}/driver/api/getDriverDetailsProxy/${driverId}?partnerId=${partnerId}`,
  },
  partner: {
    ids: `${baseUrl}/general/api/partners/getListOfPartnerIdsWithDetails`,
    details: (partnerId) => `${baseUrl}/general/api/partners/${partnerId}`,
    billStatus: (partnerId) =>
      `${baseUrl}/general/api/partners/partnerBillStatusProxy/${partnerId}`,
  },
  device: {
    ids: `${baseUrl}/general/api/general/getDeviceIds`,
  },
  battery: {
    ids: `${baseUrl}/battery/api/batteries/getListOfBatteries`,
  },
  manualSwap: {
    post: `${baseUrl}/swap/swap/v2/addManualSwap`,
  },
  cashCollection: {
    post: `${baseUrl}/payments/payment/createCashCollectionEntry`,
  },
  registerCashCollection: {
    post: `${baseUrl}/payments/payment/registerCashCollection`,
  },
  walletTopUp: {
    post: `${baseUrl}/payments/payment/walletTopUp`,
  },
  openDoorCommand: {
    post: `${baseUrl}/iot/iot/openDoorCommand`,
  },
};
