export const QUERYPARAMS = {
  INVENTORY: "inventory",
  DIGITAL_TWIN: "digitalTwin",
};

export const QUERYPARAMSNAME = {
  SWAP_TYPE: "swapType",
};

export const SKIP_BATTERY_DETECTION_REASON = "Battery not detected";

export const reasonOptions = {
  inventory: [
    { value: "Power cut", label: "Power cut" },
    {
      value: "No charged battery in station",
      label: "No charged battery in station",
    },
    { value: "Internet/WiFi issue", label: "Internet/WiFi issue" },
    { value: "Driver hold to active", label: "Driver hold to active" },
    { value: "Other", label: "Other" },
  ],
  digitalTwin: [
    {
      value: SKIP_BATTERY_DETECTION_REASON,
      label: SKIP_BATTERY_DETECTION_REASON,
    },
    {
      value: "Shop owner not present",
      label: "Shop Owner not present",
    },
    { value: "Station door issue", label: "Station door issue" },
    {
      value: "Bluetooth connection issue",
      label: "Bluetooth connection issue",
    },
    {
      value: "Partner payment pending / app disabled",
      label: "Partner payment pending / app disabled",
    },
    {
      value: "Driver ID card not available",
      label: "Driver ID card not available",
    },
    { value: "Other", label: "Other" },
  ],
};

export const ACTION_TYPE = {
  AUTO: "auto",
  MANUAL: "manual",
};

export const DIGITAL_TWIN_ACTION = {
  IN_BATTERY: "inBattery",
  OUT_BATTERY: "outBattery",
};
