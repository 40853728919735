import { Box } from "@mui/material";
import InfoCard from "../InfoCard";
import { getFirebaseTimestampString } from "../../utils";

const DriverDetailsCards = ({ driverData }) => {
  return (
    <Box>
      <InfoCard
        data={driverData}
        title="Driver Details"
        bgcolor="#1DA7A7"
        fieldsToShow={{
          "Driver Name": "name",
          "Driver ID": "driverId",
          "Swap Number": "numberOfSwap",
          "Non-swap days this month": "inActiveDaysThisMonth",
          "Daily rental collected this month": "penaltyCollectedThisMonth",
        }}
      />
      <InfoCard
        data={driverData}
        title="Driver Payment Details"
        bgcolor="#2196F3"
        fieldsToShow={{
          "Unpaid Daily Rental": "unpaidDailyRentalExcludingTodaysRental",
          "Today’s Daily Rental": "todaysDailyRental",
          "Swap Fee": "swapFee",
          "Collection Amount": "collectionAmount",
        }}
      />

      {driverData?.doesDriverHaveActiveSubscription && (
        <InfoCard
          data={{
            ...driverData.subscriptionPlan.planDetails,
            validFrom: getFirebaseTimestampString(
              driverData.subscriptionPlan.validFrom
            ),
            validTill: getFirebaseTimestampString(
              driverData.subscriptionPlan.validTill
            ),
            numberOfSwapLeftFromLatestSubscription:
              driverData.numberOfSwapLeftFromLatestSubscription,
          }}
          title="SP Details"
          bgcolor="#EDB35D"
          fieldsToShow={{
            "SP Name": "planName",
            "Valid From": "validFrom",
            "Valid Upto": "validTill",
            "Free Swaps Left": "numberOfSwapLeftFromLatestSubscription",
            "Free Swaps Allotted": "freeSwapIncluded",
          }}
        />
      )}
    </Box>
  );
};

export default DriverDetailsCards;
