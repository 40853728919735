import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useStepperContext } from "../StepperContext";
import InfoCard from "../InfoCard";
import DriverDetailsCards from "./DriverDetailsCards";
import { QUERYPARAMS } from "../common";

const ViewCards = ({ view }) => {
  const { data } = useStepperContext();
  const { swapType, driverId, partnerId } = data;

  // const { data: driverDetails = {} } = useQuery({
  //   queryKey: ["newDriverDetails", driverId],
  //   queryFn: () => getDriverDetails(driverId, partnerId),
  // });

  const cardConfigs = useMemo(
    () => ({
      partnerDetails: {
        title: "SMB Details",
        fields: {
          "SMB Name": "partnerName",
          "SMB ID": "partnerId",
          "Station IMEI": "deviceId",
        },
        data: data.partnerDetails,
      },
      driverDetails: {
        component: <DriverDetailsCards driverData={data.driverDetails} />,
      },
      inBatteryDetails: {
        title: "In Battery Details",
        fields:
          swapType === QUERYPARAMS.INVENTORY
            ? {
                "In Battery ID": "insertedBatteryId",
                "In Battery Voltage": "insertedBatteryVoltage",
              }
            : {
                "In Battery ID": "insertedBatteryId",
              },
        data,
      },
      inBatteryDetailsFromDigitalTwin: {
        title: "In Battery Details",
        fields: {
          "In Battery ID": data.insertedBatteryIdDT
            ? "insertedBatteryIdDT"
            : "insertedBatteryId",
          "In Battery SOC": "insertedBatterySocDT",
        },
        data,
      },
      outBatteryDetails: {
        title: "Out Battery Details",
        fields: {
          "Out Battery ID": "takenBatteryId",
          "Out Battery Voltage": "takenBatteryVoltage",
        },
        data,
      },
      outBatteryDetailsFromDigitalTwin: {
        title: "Out Battery Details",
        fields: {
          "Out Battery ID": "takenBatteryIdDT",
          "Out Battery SOC": "takenBatterySocDT",
        },
        data,
      },
    }),
    [data, swapType]
  );

  const cardConfigView = useMemo(
    () =>
      swapType === QUERYPARAMS.DIGITAL_TWIN
        ? [
            "partnerDetails",
            "driverDetails",
            "inBatteryDetailsFromDigitalTwin",
            "outBatteryDetailsFromDigitalTwin",
          ]
        : [
            "partnerDetails",
            "driverDetails",
            "inBatteryDetails",
            "outBatteryDetails",
          ],
    [swapType]
  );

  const renderInfoCard = (config) => (
    <InfoCard
      data={config.data}
      title={config.title}
      bgcolor="#1DA7A7"
      fieldsToShow={config.fields}
    />
  );

  const renderCard = () => {
    if (view === "all") {
      return cardConfigView.map((key, index) => {
        const config = cardConfigs[key];
        return (
          <div key={config.title + "-" + index}>
            {config.component || renderInfoCard(config)}
          </div>
        );
      });
    }

    const config = cardConfigs[view];
    return (
      <div key={config.title}>{config.component || renderInfoCard(config)}</div>
    );
  };

  return <div>{renderCard()}</div>;
};

ViewCards.propTypes = {
  view: PropTypes.oneOf([
    "partnerDetails",
    "inBatteryDetails",
    "inBatteryDetailsFromDigitalTwin",
    "outBatteryDetails",
    "outBatteryDetailsFromDigitalTwin",
    "driverDetails",
    "all",
  ]).isRequired,
};

export default ViewCards;
