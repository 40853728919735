import { Box } from "@mui/material";
import logoPng from "./assets/logo.png";
import { useNavigate } from "react-router-dom";

const PageLayout = ({ children, sx }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ mb: 4, px: "6px" }}>
      <Box sx={{ my: 4, textAlign: "center" }}>
        <img
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
          src={logoPng}
          alt="logo"
        />
      </Box>
      <Box
        sx={{
          width: { xs: "99%", sm: "70%", md: "60%", lg: "50%" },
          m: "auto",
          textAlign: "left",
          border: "1px solid #a3a3a3",
          boxSizing: "border-box",
          p: { xs: 1, sm: 2, md: 5 },
          pb: "30px !important",
          pt: "16px !important",
          position: "relative",
          borderRadius: "5px",
          overflow: "hidden",
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageLayout;
