import { Chip, Stack, Typography } from "@mui/material";

const InfoCard = ({ data, title, bgcolor, color, sx, fieldsToShow }) => {
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return value.join(", ");
    } else if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return value;
  };

  return (
    <Stack
      sx={{
        border: "1px solid #0C0C0C5C",
        borderRadius: 2,
        maxWidth: "380px",
        p: 2,
        mt: 2,
        ...sx,
      }}
    >
      {title && (
        <Chip
          sx={{
            bgcolor: bgcolor || "#1DA7A7",
            color: color || "#FFFFFF",
            fontWeight: 600,
          }}
          label={title}
        />
      )}

      {data &&
        fieldsToShow &&
        Object.entries(fieldsToShow).map(([key, value]) => (
          <Typography key={key} variant="body2" sx={{ mt: 1 }}>
            <strong>{`${key}: `}</strong>
            {renderValue(data[value])}
          </Typography>
        ))}
    </Stack>
  );
};

export default InfoCard;
