import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
} from "react";
import PropTypes from "prop-types";

// Action types
const UPDATE_DATA = "UPDATE_DATA";
const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
const SET_STEPS = "SET_STEPS";
const RESET_STEPPER = "RESET_STEPPER";

// Initial state
const initialState = {
  data: {},
  activeStep: 0,
  steps: [],
};

const stepperReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_DATA:
      return { ...state, data: { ...state.data, [action.key]: action.value } };
    case SET_ACTIVE_STEP:
      return { ...state, activeStep: action.step };
    case SET_STEPS:
      return { ...state, steps: action.steps };
    case RESET_STEPPER:
      return { ...initialState, steps: action.steps };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

// Create context
const StepperContext = createContext();

// Custom hook to use the stepper context
export const useStepperContext = () => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error("useStepperContext must be used within a StepperProvider");
  }
  return context;
};

// StepperProvider component
export const StepperProvider = ({ children, initialSteps, initStepsReset }) => {
  const [state, dispatch] = useReducer(stepperReducer, {
    ...initialState,
    steps: initialSteps,
  });

  const updateData = useCallback((key, value) => {
    dispatch({ type: UPDATE_DATA, key, value });
  }, []);

  const setActiveStep = useCallback((step) => {
    dispatch({ type: SET_ACTIVE_STEP, step });
  }, []);

  const setSteps = useCallback((steps) => {
    dispatch({ type: SET_STEPS, steps });
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep(Math.min(state.activeStep + 1, state.steps.length - 1));
  }, [state.activeStep, state.steps.length]);

  const handleBack = useCallback(() => {
    setActiveStep(Math.max(state.activeStep - 1, 0));
    updateData("swapErrorRes", null);
  }, [state.activeStep]);

  const handleReset = useCallback(() => {
    dispatch({ type: RESET_STEPPER, steps: initialSteps });
    initStepsReset();
  }, [initialSteps, initStepsReset]);

  const value = {
    data: state.data,
    steps: state.steps,
    activeStep: state.activeStep,
    updateData,
    setSteps,
    handleNext,
    handleBack,
    handleReset,
    setActiveStep,
  };

  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};

StepperProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialSteps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  initStepsReset: PropTypes.func.isRequired,
};

export default StepperProvider;
