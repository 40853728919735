import React from "react";
import {
  CommonLastStep,
  CommonStepOne,
  CommonStepThree,
  CommonStepTwo,
  QUERYPARAMS,
} from "./common";
import { InventoryStepFour } from "./inventory";
import { DigitalTwinStepFive, DigitalTwinStepFour } from "./digitalTwin";

export const renderStepContent = (step, index, data, updateData, swapType) => {
  // if (index === 0) {
  //   return (
  //     <Select
  //       options={swapOptions}
  //       value={swapOptions.find((option) => option.value === data.swapType)}
  //       onChange={(selectedOption) =>
  //         updateData("swapType", selectedOption?.value || "")
  //       }
  //     />
  //   );
  // }
  switch (index) {
    case 0:
      return <CommonStepOne />;
    case 1:
      return <CommonStepTwo index={index} />;
    case 2:
      return <CommonStepThree index={index} />;
    case 3:
      if (swapType === QUERYPARAMS.INVENTORY)
        return <InventoryStepFour index={index} />;
      else return <DigitalTwinStepFour index={index} />;
    case 4:
      if (swapType === QUERYPARAMS.INVENTORY) return <CommonLastStep />;
      else return <DigitalTwinStepFive index={index} />;
    case 5:
      if (swapType === QUERYPARAMS.INVENTORY) return "Unknown step";
      else return <CommonLastStep />;
    default:
      return "Unknown step";
  }
};
