import { Box, Stack, Typography } from "@mui/material";
import { getDriverDetails, getDriverIds } from "../../../../hooks/use-driver";
import { getPartnerIds } from "../../../../hooks/use-partner";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, RCSelect } from "../../../mui";
import { useStepperContext } from "../../StepperContext";
import { DriverDetailsCards, ViewCards } from "../../components";
import { useEffect, useState } from "react";
import { isEqual } from "lodash";

const StepTwo = ({ index }) => {
  const { data, activeStep, updateData } = useStepperContext();
  const [partnerIdList, setPartnerIdList] = useState([]);

  const { data: partnerIdListData = [], isLoading: isPartnerLoading } =
    useQuery({
      queryKey: ["partnerIds"],
      queryFn: getPartnerIds,
    });

  useEffect(() => {
    const newPartnerIdList = partnerIdListData.map((item) => ({
      value: `${item.partnerId}/${item.deviceId}`,
      label: `${item.partnerId}/${item.deviceId}`,
    }));

    if (JSON.stringify(newPartnerIdList) !== JSON.stringify(partnerIdList)) {
      setPartnerIdList(newPartnerIdList);
    }
  }, [partnerIdListData]);

  const { data: driverIdList = [], isLoading: isDriverLoading } = useQuery({
    queryKey: ["driverIds"],
    queryFn: getDriverIds,
  });

  const {
    data: driverDetails = {},
    isLoading: isDriverDetailsLoading,
    refetch: refetchDriverDetails,
  } = useQuery({
    queryKey: ["driverDetails", data.driverId],
    queryFn: () => getDriverDetails(data.driverId, data.partnerId),
    enabled: !!data.driverId,
  });

  useEffect(() => {
    if (data.driverId && data.partnerId) refetchDriverDetails();
    if (!data.partnerId) updateData("driverId", "");
  }, [data.driverId, data.partnerId]);

  useEffect(() => {
    if (data.driverId === driverDetails?.driverId)
      updateData("isDriverDetails", true);
  }, [driverDetails, data.driverId]);

  useEffect(() => {
    if (driverDetails && !isEqual(driverDetails, data.driverDetails)) {
      updateData("driverDetails", driverDetails);
    }
  }, [driverDetails, data.driverDetails]);

  const handleSelectedPartnerData = (selectedPartner) => {
    const value = selectedPartner?.value || "";
    const [partnerId, deviceId] = value.split("/") || ["", ""];
    const partnerDetails =
      partnerIdListData.find((partner) => partner.partnerId === partnerId) ||
      {};
    updateData("partnerId", partnerId);
    updateData("deviceId", deviceId);
    updateData("partnerDetails", partnerDetails);
  };

  return (
    <Stack gap={3}>
      <Box>
        <RCSelect
          label="Partner Details"
          options={partnerIdList}
          value={
            partnerIdList.find(
              (option) => option.value === `${data.partnerId}/${data.deviceId}`
            ) || null
          }
          onChange={handleSelectedPartnerData}
          isLoading={isPartnerLoading}
          isDisabled={activeStep > index}
        />

        {data.partnerId &&
        data.deviceId &&
        data.deviceId !== "null" &&
        data.partnerDetails ? (
          <ViewCards view="partnerDetails" />
        ) : (
          data.partnerId && (
            <Box sx={{ my: 2 }}>
              <Typography sx={{ color: "#F32424" }}>
                Station not assigned to the partner!
              </Typography>
            </Box>
          )
        )}
      </Box>

      <Box>
        <RCSelect
          label="Driver ID Details"
          options={driverIdList}
          value={
            driverIdList.find((option) => option.value === data.driverId) ||
            null
          }
          onChange={(selectedOption) => {
            updateData("isDriverDetails", false);
            updateData("driverId", selectedOption?.value || "");
          }}
          isLoading={isDriverLoading}
          isDisabled={
            !data.partnerId ||
            !data.deviceId ||
            data.deviceId === "null" ||
            activeStep > index
          }
        />

        {data.driverId && data.partnerId ? (
          !isDriverDetailsLoading ? (
            <DriverDetailsCards driverData={driverDetails} />
          ) : (
            <CircularProgress />
          )
        ) : null}
      </Box>
    </Stack>
  );
};

export default StepTwo;
