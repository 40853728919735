import AppRoutes from "./Routes";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const queryClient = new QueryClient();

function App({ signOut, user }) {
  console.log("user: ", user?.attributes);
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppRoutes signOut={signOut} user={user?.attributes} />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default withAuthenticator(App);
