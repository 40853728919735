import { Box, Stack, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getBatteryIds } from "../../../../hooks/use-battery";
import { mergeArrays } from "../../../utils/mergeArrays";
import { useStepperContext } from "../../StepperContext";
import { BRCSelect, ViewCards } from "../../components";
import { QUERYPARAMS } from "../constant";

const StepThree = ({ index }) => {
  const { data, activeStep, updateData } = useStepperContext();

  const { data: batteryIdList, isLoading: isBatteryLoading } = useQuery({
    queryKey: ["batteryIds"],
    queryFn: () => getBatteryIds(data.driverId),
  });

  const batteryOptions = mergeArrays(
    batteryIdList?.assignedBatteries,
    batteryIdList?.allBatteries
  );

  return (
    <Stack gap={3}>
      {activeStep > index ? (
        <ViewCards view="inBatteryDetails" />
      ) : (
        <>
          <BRCSelect
            label="Select inserted battery ID:"
            options={batteryOptions}
            value={batteryOptions.find(
              (option) => option.value === data.insertedBatteryId
            )}
            onChange={(selectedOption) => {
              updateData("insertedBatteryId", selectedOption?.value || "");
            }}
            isLoading={isBatteryLoading}
            isDisabled={activeStep > index}
            assignedBatteriesLength={batteryIdList?.assignedBatteries.length}
          />
          {data.swapType === QUERYPARAMS.INVENTORY ? (
            <Box>
              <Typography variant="body2">Enter battery voltage:</Typography>
              <TextField
                fullWidth
                type="number"
                size="small"
                placeholder="Battery Voltage"
                value={data.insertedBatteryVoltage || ""}
                onChange={(e) =>
                  updateData("insertedBatteryVoltage", e.target.value)
                }
                sx={{ mt: 0.5 }}
              />
            </Box>
          ) : null}
        </>
      )}
    </Stack>
  );
};

export default StepThree;
