import { TableRow, TableCell, Typography, TextField } from "@mui/material";
import { useStepperContext } from "../StepperContext";

const CRTableRow = ({
  label,
  value,
  isEditable,
  isValueBold,
  onChange,
  type = "number",
  multilineRows = 2,
  isInputMultiline = false,
}) => {
  const { data } = useStepperContext();

  return (
    <TableRow>
      <TableCell sx={{ width: "50%", p: 1 }}>
        <Typography variant="body1">{label}</Typography>
      </TableCell>
      <TableCell sx={{ width: "50%", p: 1 }} align="left">
        {isEditable ? (
          <TextField
            value={value || ""}
            onChange={onChange}
            type={type}
            variant="outlined"
            size="small"
            multiline={isInputMultiline}
            rows={multilineRows}
            fullWidth
            disabled={data.isInitCashCollection}
          />
        ) : (
          <Typography
            variant={isValueBold ? "h6" : "body1"}
            sx={{ fontWeight: isValueBold ? "bold" : "normal" }}
          >
            {value}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CRTableRow;
