import { endpoints } from "../../utils/endpoints";

export const PAYMENT_TYPE = {
  LAST_BILL_AMOUNT: "lastBillAmount",
  CURRENT_OUTSTANDING_AMOUNT: "currentOutstanding",
  WALLET_TOP_UP_AMOUNT: "walletTopUp",
};

export const paymentTypeArray = [
  { value: PAYMENT_TYPE.LAST_BILL_AMOUNT, label: "Last Bill Amount" },
  {
    value: PAYMENT_TYPE.CURRENT_OUTSTANDING_AMOUNT,
    label: "Current Outstanding",
  },
  { value: PAYMENT_TYPE.WALLET_TOP_UP_AMOUNT, label: "Wallet Top-Up" },
];

export const PAYMENT_ENDPOINT = {
  lastBillAmount: endpoints.registerCashCollection.post,
  currentOutstanding: "",
  walletTopUp: endpoints.walletTopUp.post,
};

export const PAYMENT_ERROR = {
  lastBillAmount: "*Bill Already Paid.",
  currentOutstanding: "",
  walletTopUp:
    "*Wallet Top-up cannot be done. Partner does not have any negative bill amount.",
};
