export function mergeArrays(array1, array2) {
  if (array1 && array2) {
    const addedElements = new Set(array1);

    const divider = { label: "Divider", value: "divider", isDivider: true };

    const array1Mapped = array1.map((item, index) => ({
      label: item,
      value: item,
      index: index,
    }));

    const array2Filtered = array2
      .filter((item) => !addedElements.has(item))
      .map((item, index) => ({
        label: item,
        value: item,
        index: array1.length + 1 + index,
      }));

    const resultArray = [...array1Mapped, divider, ...array2Filtered];

    return resultArray;
  }
  return [];
}
