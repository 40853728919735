import { Route, Routes } from "react-router-dom";
import {
  HomePage,
  CashCollection,
  // ManualSwap,
  PageNotFound,
  SwapTypePage,
  CashCollectionLatest,
} from "./layout";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = ({ signOut, user }) => {
  return (
    <Routes>
      <Route path="/" element={<HomePage signOut={signOut} user={user} />} />
      <Route
        path="/cash-collection"
        element={
          <ProtectedRoute user={user} feature="cash">
            <CashCollectionLatest />
          </ProtectedRoute>
        }
      />
      <Route
        path="/old-cash-collection"
        element={
          <ProtectedRoute user={user} feature="cash">
            <CashCollection />
          </ProtectedRoute>
        }
      />
      <Route
        path="/manual-swap"
        element={
          <ProtectedRoute user={user} feature="manual">
            {/* <ManualSwap /> */}
            <SwapTypePage />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
