import { Stack } from "@mui/material";
import { ViewCards } from "../../components";

const LastStep = () => {
  return (
    <Stack gap={3}>
      <ViewCards view="all" />
    </Stack>
  );
};

export default LastStep;
