import React, { useEffect, useState } from "react";
import { renderStepContent } from "./renderStepContent";
import { StepperProvider, useStepperContext } from "./StepperContext";
import PageLayout from "../PageLayout";
import { PageTitle } from "../utils";
import VerticalLinearStepper from "./Stepper";

export const steps = [
  {
    label: "Please Enter Partner ID",
    actionCompleted: false,
    expanded: true,
  },
  {
    label: "Type of Payment",
    actionCompleted: false,
    expanded: true,
  },
  {
    label: "Payment Details",
    actionCompleted: false,
    expanded: true,
  },
  {
    label: "Success/Failure",
    actionCompleted: false,
    expanded: true,
  },
];

const stepCompletionActions = [
  (steps, setSteps) => {
    const updatedSteps = [...steps];
    updatedSteps[0].actionCompleted = true;
    setSteps(updatedSteps);
  },
  (steps, setSteps) => {
    const updatedSteps = [...steps];
    updatedSteps[1].actionCompleted = true;
    setSteps(updatedSteps);
  },
  (steps, setSteps) => {
    const updatedSteps = [...steps];
    updatedSteps[2].actionCompleted = true;
    setSteps(updatedSteps);
  },
];

const CashCollection = () => {
  return (
    <StepperProvider initialSteps={steps}>
      <PageLayout>
        <PageTitle title="Cash Collection" />
        <VerticalLinearStepper
          renderStepContent={renderStepContent}
          useStepperContext={useStepperContext}
          onStepCompletion={stepCompletionActions}
        />
      </PageLayout>
    </StepperProvider>
  );
};

export default CashCollection;
