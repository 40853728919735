import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Watch } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const DeviceStatusDialog = ({ open, syncTimestamp }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/manual-swap`);
  };
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{
        width: { xs: "90%", sm: "50%", md: "30%" },
        p: 0,
        m: "auto",
        "& .MuiDialog-paper": {
          borderRadius: "20px",
          p: 1,
        },
        textAlign: "center",
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1, textAlign: "center" }}>
        <Chip color="error" label="! Station Unavailable" />
      </DialogTitle>

      <DialogContent sx={{ my: 2 }}>
        <Typography
          variant="body2"
          sx={{ fontSize: "14px", mb: 1, fontWeight: 600 }}
        >
          Last Sync: {syncTimestamp ? syncTimestamp : "--"}
        </Typography>
        <Typography variant="body2">
          The station went offline. Ensure internet connectivity for the station
          and wait till the station is back online.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            mt: 2,
          }}
        >
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#1DA7A7"
          />
          <Button variant="outlined" autoFocus onClick={handleNavigate}>
            CANCEL SWAP
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeviceStatusDialog;
