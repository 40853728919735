import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCq0F29FnaTUkU4_vVTdMzSSP61N5yxuqg",
  authDomain: "batterypoolapp.firebaseapp.com",
  databaseURL: "https://batterypoolapp.firebaseio.com",
  projectId: "batterypoolapp",
  storageBucket: "batterypoolapp.appspot.com",
  messagingSenderId: "299872120294",
  appId: "1:299872120294:web:21a9855ab61765e24efa27",
  measurementId: "G-66BTG9RV3X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, db, collection, getDocs, getDoc, doc, onSnapshot, Timestamp };
