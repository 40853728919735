import React, { useEffect, useState } from "react";
import VerticalLinearStepper from "../mui/Stepper";
import { renderStepContent } from "./renderStepContent";
import { StepperProvider, useStepperContext } from "./StepperContext";
import PageLayout from "../PageLayout";
import { PageTitle } from "../utils";
import { useLocation } from "react-router-dom";
import { QUERYPARAMS, QUERYPARAMSNAME } from "./common";

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const commonSteps = [
  {
    label: "Select Reason for Manual Swap",
    actionCompleted: false,
  },
  {
    label: "Enter SMB and Driver Details",
    actionCompleted: false,
    expanded: true,
  },
  {
    label: "Enter IN battery details",
    actionCompleted: false,
    expanded: true,
  },
];

export const inventorySteps = [
  {
    label: "Enter OUT battery details",
    actionCompleted: false,
    expanded: true,
  },
  {
    label: "Swap Summary",
    actionCompleted: false,
    expanded: true,
  },
];

export const digitalTwinSteps = [
  {
    label: "Digital Twin - Insert IN Battery",
    actionCompleted: false,
    expanded: true,
  },
  {
    label: "Digital Twin - Collect OUT battery",
    actionCompleted: false,
    expanded: true,
  },
  {
    label: "Swap Summary",
    actionCompleted: false,
    expanded: true,
  },
];

const addDigitalTwinSteps1 = (data, steps, setSteps) => {
  const updatedSteps = [...steps];
  updatedSteps[3].label = `Digital Twin - IN Battery Details Confirmed`;
  updatedSteps[3].actionCompleted = true;
  setSteps(updatedSteps);
};
const addDigitalTwinSteps2 = (data, steps, setSteps) => {
  const updatedSteps = [...steps];
  updatedSteps[4].label = `Digital Twin - OUT Battery Details Confirmed`;
  updatedSteps[4].actionCompleted = true;
  setSteps(updatedSteps);
};

const stepCompletionActions = [
  // (data, steps, setSteps) => {
  //   const updatedSteps = [...steps];
  //   updatedSteps[0].label = `Swap type selected - ${data.swapType}`;
  //   updatedSteps[0].actionCompleted = true;
  //   setSteps(updatedSteps);
  // },
  (data, steps, setSteps) => {
    const updatedSteps = [...steps];
    updatedSteps[0].label = `Swap reason selected - ${
      data.swapReason === "Other" ? data.customReason : data.swapReason
    }`;
    updatedSteps[0].actionCompleted = true;
    setSteps(updatedSteps);
  },
  (data, steps, setSteps) => {
    const updatedSteps = [...steps];
    updatedSteps[1].label = `Driver and SMB Selected`;
    updatedSteps[1].actionCompleted = true;
    setSteps(updatedSteps);
  },
  (data, steps, setSteps) => {
    const updatedSteps = [...steps];
    updatedSteps[2].label = `IN Battery Details Confirmed`;
    updatedSteps[2].actionCompleted = true;
    setSteps(updatedSteps);
  },
  (data, steps, setSteps) => {
    if (data.swapType === QUERYPARAMS.DIGITAL_TWIN) {
      addDigitalTwinSteps1(data, steps, setSteps);
      addDigitalTwinSteps2(data, steps, setSteps);
    } else {
      const updatedSteps = [...steps];
      updatedSteps[3].label = `OUT Battery Details Confirmed`;
      updatedSteps[3].actionCompleted = true;
      setSteps(updatedSteps);
    }
  },
];

const ManualSwap = () => {
  const [initialSteps, setInitialSteps] = useState([]);
  const [stepperKey, setStepperKey] = useState(Date.now());
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const swapType = queryParams.get(QUERYPARAMSNAME.SWAP_TYPE);

  const handleInitialSteps = () => {
    if (swapType === QUERYPARAMS.DIGITAL_TWIN) {
      let initialStepsData = commonSteps.concat(digitalTwinSteps);
      setInitialSteps(deepClone(initialStepsData));
    } else {
      let initialStepsData = commonSteps.concat(inventorySteps);
      setInitialSteps(deepClone(initialStepsData));
    }
    setStepperKey(Date.now());
  };

  useEffect(() => {
    handleInitialSteps();
  }, [swapType]);

  const getPageTitle = (swapType) => {
    if (swapType === QUERYPARAMS.DIGITAL_TWIN)
      return `Digital Twin: Manual Swap`;
    if (swapType === QUERYPARAMS.INVENTORY) return `Inventory: Manual Swap`;
  };

  return (
    <StepperProvider
      key={stepperKey}
      initStepsReset={handleInitialSteps}
      initialSteps={initialSteps}
    >
      <PageLayout>
        <PageTitle title={getPageTitle(swapType)} />
        <VerticalLinearStepper
          renderStepContent={renderStepContent}
          useStepperContext={useStepperContext}
          onStepCompletion={stepCompletionActions}
          swapType={swapType}
        />
      </PageLayout>
    </StepperProvider>
  );
};

export default ManualSwap;
