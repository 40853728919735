import React from "react";
import styled, { keyframes } from "styled-components";
import Button from "@mui/material/Button";

const dotPulse = keyframes`
  0% {
    transform: translate(-40px) scale(0);
  }

  50% {
    transform: translate(0px) scale(1);
  }

  100% {
    transform: translate(40px) scale(0);
  }
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  animation: ${dotPulse} 1s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.33s;
  }
  &:nth-child(3) {
    animation-delay: 0.66s;
  }
`;

const LoaderButton = ({
  variant = "contained",
  onClick,
  isLoading,
  disabled,
  children,
  sx,
}) => {
  const disabledStyle = isLoading
    ? {
        "&.Mui-disabled": {
          border: "1px solid #1DA7A7",
          backgroundColor: "#1DA7A7",
          color: "#FFFFFF",
          opacity: 1,
        },
      }
    : {};
  return (
    <Button
      className={`loading-button ${isLoading ? "activeLoading" : ""}`}
      variant={variant}
      disabled={isLoading || disabled}
      onClick={onClick}
      sx={{
        height: "40px",
        minWidth: "120px",
        width: "100%",
        ...disabledStyle,
        ...sx,
      }}
    >
      {isLoading ? (
        <Loader>
          <Dot />
          <Dot />
          <Dot />
        </Loader>
      ) : (
        children
      )}
    </Button>
  );
};

export default LoaderButton;
