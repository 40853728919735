import { Box, Stack, Typography } from "@mui/material";
import { Circle } from "@mui/icons-material";

const StationSyncStatus = ({
  syncTimestamp,
  stationIMEI,
  partnerId,
  isOnline,
}) => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "left", md: "flex-start" }}
      mb={{ xs: 2, md: 3 }}
      gap={1}
    >
      <Box>
        <CTypography>Station IMEI: {stationIMEI || "--"}</CTypography>
        <CTypography sx={{ mt: { xs: 1, md: 1.2 } }}>
          Partner ID: {partnerId || "--"}
        </CTypography>
      </Box>
      <Box>
        <CTypography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-end",
            },
          }}
        >
          <span style={{ marginRight: 4 }}>Station Status:</span>
          {isOnline ? (
            <span
              style={{
                color: "#9ADB83",
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <Circle sx={{ fontSize: "12px", ml: 1, mr: 0.5 }} /> Online
            </span>
          ) : (
            <span
              style={{
                color: "#B31312",
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <Circle sx={{ fontSize: "12px", ml: 1, mr: 0.5 }} /> Offline
            </span>
          )}
        </CTypography>
        <CTypography sx={{ fontStyle: "italic", mt: { xs: 1, md: 1.2 } }}>
          Last Sync: {syncTimestamp || "--"}
        </CTypography>
      </Box>
    </Stack>
  );
};

export default StationSyncStatus;

const CTypography = ({ children, sx }) => {
  return (
    <Typography variant="body2" sx={{ fontSize: "13px", ...sx }}>
      {children}
    </Typography>
  );
};
