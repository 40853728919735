import { RCSelect } from "../../mui";

const getCustomStyles = (assignedBatteriesLength) => ({
  option: (provided, state) => {
    if (state.data.isDivider) {
      return {
        ...provided,
        backgroundColor: "transparent",
        cursor: "default",
        color: "transparent",
        height: "1px",
        borderBottom: "2px solid #ccc",
        margin: "4px 0",
      };
    }
    return {
      ...provided,
      color:
        state.data.index < assignedBatteriesLength && !state.isSelected
          ? "#1DA7A7"
          : provided.color,
    };
  },
  loadingIndicator: (base) => ({
    ...base,
    color: "#1DA7A7",
  }),
});

const BRCSelect = ({
  label,
  options,
  value,
  onChange,
  isLoading = false,
  isDisabled = false,
  styles = {},
  isHighlighter = true,
  highlightStyle,
  assignedBatteriesLength,
  isVisualization = true,
}) => {
  const customStyles = getCustomStyles(assignedBatteriesLength || 0);

  return (
    <RCSelect
      label={label}
      options={options}
      value={value}
      onChange={onChange}
      isLoading={isLoading}
      isDisabled={isDisabled}
      styles={{ ...customStyles, ...styles }}
      isHighlighter={isHighlighter}
      highlightStyle={highlightStyle}
      isVisualization={isVisualization}
    />
  );
};

export default BRCSelect;
