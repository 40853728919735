import axios from "axios";
import { endpoints } from "../utils/endpoints";

export const getDriverIds = async () => {
  const { data } = await axios.get(endpoints.driver.ids);
  return data.data.map((item) => ({
    value: item,
    label: item,
  }));
};

export const getDriverDetails = async (driverId, partnerId) => {
  if (driverId && partnerId) {
    const { data } = await axios.get(
      endpoints.driver.details(driverId, partnerId)
    );
    return data.data;
  }
  return {};
};
