import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const PageTitle = ({ title }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: { xs: 3, md: 5 },
        gap: { xs: 2, sm: 4, md: 5 },
      }}
    >
      <IconButton
        sx={{
          bgcolor: "#e7e7e7",
          ":hover": {
            bgcolor: "#d8d8d8",
          },
        }}
        onClick={() => navigate("/")}
      >
        <HomeIcon sx={{ color: "#1DA7A7" }} />
      </IconButton>
      <Typography
        variant="h4"
        sx={{
          fontSize: { xs: 20, sm: 26, md: 30 },
          p: 0,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PageTitle;
