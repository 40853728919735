import { useEffect } from "react";
import { RCSelect } from "../../mui";
import { PAYMENT_ERROR, PAYMENT_TYPE, paymentTypeArray } from "../constant";
import { useStepperContext } from "../StepperContext";
import { getPartnerBillStatus } from "../../../hooks/use-partner";

const StepTwo = ({ index }) => {
  const { data, activeStep, updateData } = useStepperContext();

  useEffect(() => {
    const fetchPartnerBillStatus = async () => {
      try {
        if (data.paymentType) {
          updateData("isPartnerBillStatusLoading", true);
          let partnerBillStatus = await getPartnerBillStatus(data.partnerId);
          if (partnerBillStatus?.billStatus)
            updateData("isPartnerBillStatusLoading", false);
          updateData("partnerBillStatus", partnerBillStatus);

          if (partnerBillStatus) updateData("isPaymentType", true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPartnerBillStatus();
  }, [data.paymentType]);

  useEffect(() => {
    updateData("isMakeWalletTopUp", true);
    updateData("isMakeLastBillPayment", true);
    if (
      data.paymentType === PAYMENT_TYPE.WALLET_TOP_UP_AMOUNT ||
      data.paymentType === PAYMENT_TYPE.LAST_BILL_AMOUNT
    ) {
      if (data.partnerBillStatus?.billStatus === "paid" && data.partnerBillStatus?.totalPendingAmount < 0) {
        updateData("isMakeWalletTopUp", true);
        updateData("isMakeLastBillPayment", false);
      } else {
        updateData("isMakeWalletTopUp", false);
        updateData("isMakeLastBillPayment", true);
      }
    }
  }, [data.partnerBillStatus, data.paymentType]);

  const handleError = (type) => {
    switch (type) {
      case PAYMENT_TYPE.LAST_BILL_AMOUNT:
        return !data.isMakeLastBillPayment;
      case PAYMENT_TYPE.WALLET_TOP_UP_AMOUNT:
        return !data.isMakeWalletTopUp;
      default:
        break;
    }
  };

  return (
    <RCSelect
      isSearchable={false}
      // label="Select Type Of Payment"
      placeholder="Select Type Of Payment"
      options={paymentTypeArray}
      value={paymentTypeArray.find(
        (option) => option.value === data.paymentType
      )}
      onChange={(selectedOption) => {
        updateData("paymentType", selectedOption?.value || "");
        updateData("isPaymentType", false);
      }}
      isDisabled={activeStep > index}
      error={
        handleError(data.paymentType) && data.isPaymentType
          ? PAYMENT_ERROR[data.paymentType]
          : null
      }
    />
  );
};

export default StepTwo;
