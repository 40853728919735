import { LoaderButton } from "../../mui";
import { Box, Stack, Typography } from "@mui/material";
import CountdownTimer from "./CountdownTimer";
import { useStepperContext } from "../StepperContext";
import { DIGITAL_TWIN_ACTION, SKIP_BATTERY_DETECTION_REASON } from "../common";

const TIMER1_SEC = 90;
const TIMER2_SEC = 90;
const TIMER3_SEC = 180;

const DoorActionButtons = ({
  status,
  action,
  selectedDoor,
  handleOpenDoorCommand,
  handleTimerCompleted1,
  handleTimerCompleted2,
  handleTimerCompleted3,
  handleMarkedDoorClose,
  handleConfirmBatteryDetectionManual,
}) => {
  const { data } = useStepperContext();
  console.log("status:  ", status);
  console.log("data:  ", data);

  const isConfirmBatteryDetectionEnabled =
    action === DIGITAL_TWIN_ACTION.IN_BATTERY &&
    status.isDoneDoorClose &&
    status.isInitDoorOpen;
  const isMarkDoorCloseEnabled =
    (status.isDoorOpen && status.isInitDoorOpen) ||
    (status.isDoneDoorClose && status.isInitDoorOpen) ||
    status.isCompleted;

  const renderDoorStatus = () => (
    <Typography
      variant="body1"
      sx={{
        fontSize: "14px",
        bgcolor:
          !status.isDoneDoorClose && status.isDoorOpen
            ? "#079B2785"
            : "#F32424",
        color: "#FFFFFF",
        p: 1,
        borderRadius: 1,
      }}
    >
      {!status.isDoneDoorClose && status.isDoorOpen
        ? "Door Status - Open"
        : "Door Status - Closed"}
    </Typography>
  );

  const renderButton = () => {
    if (isConfirmBatteryDetectionEnabled) {
      return (
        <LoaderButton
          variant="contained"
          onClick={handleConfirmBatteryDetectionManual}
          sx={{ mt: 2 }}
          isLoading={false}
          disabled={!status.isTimerCompleted3 || status.isBatteryDetected}
        >
          CONFIRM BATTERY COLLECTION
        </LoaderButton>
      );
    } else if (isMarkDoorCloseEnabled) {
      return (
        <LoaderButton
          variant="contained"
          onClick={handleMarkedDoorClose}
          sx={{ mt: 2 }}
          isLoading={false}
          disabled={
            status.isCompleted ||
            !status.isTimerCompleted2 ||
            (status.isDoneDoorClose && selectedDoor !== null)
          }
        >
          MARK DOOR CLOSED
        </LoaderButton>
      );
    } else {
      return (
        <LoaderButton
          variant="contained"
          onClick={handleOpenDoorCommand}
          sx={{ mt: 2 }}
          isLoading={status.isInitDoorOpen && !status.isTimerCompleted1}
          disabled={
            status.isDoorOpen ||
            (status.isInitDoorOpen && !status.isTimerCompleted1)
          }
        >
          OPEN DOOR
        </LoaderButton>
      );
    }
  };

  const renderCountdownTimer = (sx) => {
    if (status.isInitDoorOpen && !status.isTimerCompleted1) {
      return (
        <CountdownTimer
          key={status.isDoorOpen}
          sx={sx}
          onComplete={handleTimerCompleted1}
          seconds={TIMER1_SEC}
        />
      );
    } else if (
      status.isTimerCompleted1 &&
      !status.isTimerCompleted2 &&
      status.isDoorOpen
    ) {
      return (
        <CountdownTimer
          key={status.isDoorOpen}
          sx={sx}
          onComplete={handleTimerCompleted2}
          seconds={TIMER2_SEC}
        />
      );
    } else if (
      action === DIGITAL_TWIN_ACTION.IN_BATTERY &&
      status.isDoneDoorClose &&
      !status.isTimerCompleted3 &&
      !status.isBatteryDetected &&
      data.swapReason !== SKIP_BATTERY_DETECTION_REASON
    ) {
      return (
        <CountdownTimer
          key={action}
          sx={sx}
          onComplete={handleTimerCompleted3}
          seconds={TIMER3_SEC}
        />
      );
    }
    return null;
  };

  return (
    <>
      {selectedDoor !== null && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "left", md: "center" }}
          mt={3}
        >
          <Box
            sx={{
              textAlign: "center",
              position: "relative",
              width: { xs: "100%", sm: "auto", md: "auto" },
            }}
          >
            {renderCountdownTimer({
              display: { xs: "block", sm: "none", md: "none" },
              mb: 1,
            })}
            {renderDoorStatus()}
            {renderButton()}
          </Box>
          {renderCountdownTimer({
            position: "absolute",
            right: { xs: 45, sm: 50, md: 100 },
            display: { xs: "none", sm: "block", md: "block" },
          })}
        </Stack>
      )}
    </>
  );
};

export default DoorActionButtons;
