import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CountdownTimer = ({
  sx,
  seconds = 30,
  visible = true,
  running = true,
  onComplete,
   text
}) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [isRunning, setIsRunning] = useState(running);
  const [isVisible, setIsVisible] = useState(visible);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!isRunning || timeLeft === 0) return;

    const endTime = Date.now() + timeLeft * 1000;

    const tick = () => {
      const timeRemaining = Math.max(
        Math.round((endTime - Date.now()) / 1000),
        0
      );
      setTimeLeft(timeRemaining);

      if (timeRemaining > 0) {
        intervalRef.current = setTimeout(tick, 1000);
      } else if (onComplete) {
        onComplete();
      }
    };

    tick();

    return () => clearTimeout(intervalRef.current);
  }, [isRunning, timeLeft, onComplete]);

  useEffect(() => {
    setTimeLeft(seconds);
  }, [seconds]);

  useEffect(() => {
    setIsRunning(running);
  }, [running]);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const progress = (timeLeft / seconds) * 100;
  const progressColor = timeLeft === 0 ? "gray" : "primary";

  if (!isVisible) return null;

  return (
    <Box sx={{ ...sx }}>
      {text}
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={100}
          size={50}
          thickness={4}
          style={{ color: "#e0e0e0" }}
        />
        <CircularProgress
          variant="determinate"
          value={progress}
          size={50}
          thickness={4}
          style={{
            position: "absolute",
            left: 0,
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" component="div" color="textSecondary">
            {timeLeft}s
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CountdownTimer;
