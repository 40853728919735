import { useLocation, useNavigate } from "react-router-dom";

import { PageTitle } from "../utils";
import PageLayout from "../PageLayout";
import PageNotFound from "./PageNotFound";
import { Box, Button } from "@mui/material";
import ManualSwap from "../manualSwapLatest/ManualSwap";
import { QUERYPARAMS, QUERYPARAMSNAME } from "../manualSwapLatest/common";

const SwapTypePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const swapType = queryParams.get(QUERYPARAMSNAME.SWAP_TYPE);

  const handleNavigate = (swapType) => {
    navigate(`/manual-swap?${QUERYPARAMSNAME.SWAP_TYPE}=${swapType}`);
  };

  return (
    <>
      {swapType ? (
        swapType === QUERYPARAMS.INVENTORY ||
        swapType === QUERYPARAMS.DIGITAL_TWIN ? (
          <ManualSwap />
        ) : (
          <PageNotFound />
        )
      ) : (
        <PageLayout>
          <PageTitle title="Manual Swap" />
          <Box
            sx={{
              width: "100%",
              textAlign: "left",
              display: "grid",
              gridTemplateColumns: {
                xs: `repeat(1, 100%)`,
                sm: `repeat(2, 45%)`,
                md: `repeat(2, 45%)`,
              },
              justifyContent: "space-around",
              gap: 5,
              mt: "12%",
            }}
          >
            <CButton onClick={() => handleNavigate(QUERYPARAMS.INVENTORY)}>
              Inventory
            </CButton>
            <CButton onClick={() => handleNavigate(QUERYPARAMS.DIGITAL_TWIN)}>
              Digital Twin
            </CButton>
          </Box>
        </PageLayout>
      )}
    </>
  );
};

export default SwapTypePage;

const CButton = ({ children, onClick }) => {
  return (
    <Button
      variant="contained"
      sx={{
        p: "10px",
        py: { xs: "15px", sm: "20px", md: "20px" },
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
