import { Typography } from "@mui/material";
import { DIGITAL_TWIN_ACTION } from "../common";
import { DigitalTwin } from "../components";
import { useStepperContext } from "../StepperContext";

const StepFive = ({ index }) => {
  const { data } = useStepperContext();
  return (
    <>
      <DigitalTwin
        index={index}
        action={DIGITAL_TWIN_ACTION.OUT_BATTERY}
        batteryIdKey="takenBatteryIdDT"
        batterySocKey="takenBatterySocDT"
        swapActionStartKey="batteryOutStartFromDigitalTwin"
        swapActionCompleteKey="batteryOutCompleteFromDigitalTwin"
        batteryDetailsViewKey="outBatteryDetailsFromDigitalTwin"
        batteryDoorCloseConfirmKey="takenBatteryDoorCloseConf"
        // component key
        key="batteryOutFromDigitalTwin"
      />

      {data?.swapErrorRes && (
        <Typography variant="body2" color="error" mt={1}>
          {`${data.swapErrorRes.code}: ${data.swapErrorRes.message}`}
        </Typography>
      )}
    </>
  );
};

export default StepFive;
