import { Box, Button } from "@mui/material";
import { serviceTab } from "./constant";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          display: "grid",
          gridTemplateColumns: {
            xs: `repeat(1, 100%)`,
            sm: `repeat(2, 45%)`,
            md: `repeat(2, 45%)`,
          },
          justifyContent: "space-between",
          gap: 5,
          mt: "12%",
        }}
      >
        {serviceTab.map((item) => (
          <Button
            key={item.route}
            variant="contained"
            sx={{
              p: "10px",
              py: { xs: "25px", sm: "40px", md: "40px" },
              fontSize: { xs: "18px", sm: "20px", md: "25px" },
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            onClick={() => handleNavigate(item.route)}
          >
            {item.text}
          </Button>
        ))}
      </Box>

      <Button
        variant="outlined"
        sx={{ mt: 3 }}
        onClick={() => handleNavigate("/old-cash-collection")}
      >
        Old Cash Collection
      </Button>
    </Box>
  );
};

export default Services;
