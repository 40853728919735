import Lottie from "lottie-react";
import successAnimation from "../assets/success.json";
import errorAnimation from "../assets/error.json";
import { useStepperContext } from "../StepperContext";
import { Box, Button, Typography } from "@mui/material";

const BillPaid = () => {
  const { data, handleReset } = useStepperContext();

  return (
    <Box sx={{ width: "100%", m: "auto", textAlign: "center" }}>
      {data.isCompletedCashCollection ? (
        <>
          <Typography variant="h6" sx={{ color: "#416D19" }}>
            PAYMENT RECORDED
          </Typography>

          <Lottie
            animationData={successAnimation}
            loop={true}
            style={{ maxWidth: "200px", margin: "auto", p: 0 }}
          />
        </>
      ) : (
        <>
          <Typography variant="h6" sx={{ color: "#EE4E4E" }}>
            PAYMENT UNSUCCESSFUL
          </Typography>
          {data.errorMsg ? (
            <Typography variant="body2" color="error" mt={1}>
              {data.errorMsg}
            </Typography>
          ) : null}
          <Lottie
            animationData={errorAnimation}
            loop={true}
            style={{ maxWidth: "200px", margin: "auto", p: 0 }}
          />
        </>
      )}

      <Button variant="contained" onClick={handleReset}>
        Reset
      </Button>
    </Box>
  );
};

export default BillPaid;
