import { DIGITAL_TWIN_ACTION } from "../common";
import { DigitalTwin } from "../components";

const StepFour = ({ index }) => {
  return (
    <DigitalTwin
      index={index}
      action={DIGITAL_TWIN_ACTION.IN_BATTERY}
      batteryIdKey="insertedBatteryIdDT"
      batterySocKey="insertedBatterySocDT"
      swapActionStartKey="batteryInsertionStartFromDigitalTwin"
      swapActionCompleteKey="batteryInsertionCompleteFromDigitalTwin"
      batteryDetailsViewKey="inBatteryDetailsFromDigitalTwin"
      batteryDoorCloseConfirmKey="insertedBatteryDoorCloseConf"
      // component key
      key="batteryInsertionFromDigitalTwin"
    />
  );
};

export default StepFour;
