import { useState } from "react";
import axios from "axios";
import { endpoints } from "../../../utils/endpoints";

const useCashCollection = (onSuccess) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [resData, setResData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const onSubmit = (payload, apiEndpoint) => {
    setIsLoading(true);

    let config = {
      method: "post",
      url: apiEndpoint,
      // headers: {
      //   "x-api-key": "D5xTEWXbE85SBhH23736K9O07LoYsKo52PBERgGV",
      //   "Content-Type": "application/json",
      // },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setResData(response.data.message);
        setIsLoading(false);
        setIsSuccess(true);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMsg(error.message);
        setIsLoading(false);
        setIsSuccess(false);
        if (onSuccess) {
          onSuccess();
        }
      });
  };

  return { isLoading, isSuccess, resData, errorMsg, onSubmit };
};

export default useCashCollection;
