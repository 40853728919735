import { useEffect } from "react";
import Box from "@mui/material/Box";
import AnimButton from "./AniButton";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import LoaderButton from "./LoaderButton";
import { Done } from "@mui/icons-material";
import Stepper from "@mui/material/Stepper";
import { useNavigate } from "react-router-dom";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepContent from "@mui/material/StepContent";
import { ACTION_TYPE, QUERYPARAMS } from "../manualSwapLatest/common";
import useManualSwap from "../manualSwapLatest/hooks/use-manual-swap";

function CustomStepIcon(props) {
  const { active, completed, index } = props;
  return (
    <Box
      sx={{
        width: active ? 40 : 30,
        height: active ? 40 : 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: active || completed ? "#1DA7A7" : "grey.400",
        color: "#FFFFFF",
        borderRadius: "50%",
        ml: active ? "0px" : "5px",
      }}
    >
      {completed ? (
        <Done />
      ) : (
        <Typography variant={active ? "h6" : "body2"} sx={{ fontWeight: 600 }}>
          {index + 1}
        </Typography>
      )}
    </Box>
  );
}

export default function VerticalLinearStepper({
  renderStepContent,
  useStepperContext,
  onStepCompletion,
  swapType,
}) {
  const {
    data,
    activeStep,
    updateData,
    handleNext,
    handleBack,
    handleReset,
    steps,
    setSteps,
  } = useStepperContext();

  const navigate = useNavigate();

  const handleStepCompletion = () => {
    if (onStepCompletion && onStepCompletion[activeStep]) {
      onStepCompletion[activeStep](data, steps, setSteps);
    }
    handleNext();
  };

  const onSuccess = () => {
    if (onStepCompletion && onStepCompletion[activeStep]) {
      onStepCompletion[activeStep](data, steps, setSteps);
    }
    handleNext();
  };

  const { isLoading, onSubmitManualSwap } = useManualSwap(onSuccess);

  const handleSubmit = () => {
    let payload = {
      deviceId: data.deviceId,
      partnerId: data.partnerId,
      driverId: data.driverId,
      // deviceId: "869640058240626",
      // partnerId: "tp2_id",
      // driverId: "TEST-DRIVER-6",
      manualSwapType: swapType,
      reasonForSwap: data.customReason || data.swapReason,
    };
    if (swapType === QUERYPARAMS.INVENTORY) {
      payload = {
        ...payload,
        insertedBatteryId: data.insertedBatteryId,
        takenBatteryId: data.takenBatteryId,
        takenBatteryVoltage: data.takenBatteryVoltage,
        insertedBatteryVoltage: data.insertedBatteryVoltage,
      };
    } else {
      payload = {
        ...payload,
        // battery insert
        insertedBatteryId: data.insertedBatteryIdDT || data.insertedBatteryId,
        insertedBatterySoc: data.insertedBatterySocDT || 0,
        insertedBatteryDoorCloseConf: data.insertedBatteryDoorCloseConf,
        batteryInsertedDetectionConf:
          data.batteryInsertedDetectionConf || ACTION_TYPE.MANUAL,
        batteryInsertedIntoDoor: data.batteryInsertedIntoDoor,
        // battery taken
        takenBatteryId: data.takenBatteryIdDT,
        takenBatterySoc: data.takenBatterySocDT,
        batteryTakenFromDoor: data.batteryTakenFromDoor,
        takenBatteryDoorCloseConf: data.takenBatteryDoorCloseConf,
      };
    }
    onSubmitManualSwap(payload);
  };

  const handleNextButtonClick = (index) => {
    if (swapType === QUERYPARAMS.DIGITAL_TWIN) {
      switch (index) {
        // case 0:
        //   return index === activeStep && !swapType;
        case 0:
          if (data.swapReason !== "Other") return !data.swapReason;
          else return !data.customReason;
        case 1:
          return (
            !data.partnerId ||
            !data.driverId ||
            !data.deviceId ||
            !data.isDriverDetails
          );
        case 2:
          return !data.insertedBatteryId;
        case 3:
          return !data?.batteryInsertionCompleteFromDigitalTwin;
        case 4:
          return !data?.batteryOutCompleteFromDigitalTwin;
        default:
          break;
      }
    } else {
      switch (index) {
        // case 0:
        //   return index === activeStep && !swapType;
        case 0:
          if (data.swapReason !== "Other") return !data.swapReason;
          else return !data.customReason;
        case 1:
          return (
            !data.partnerId ||
            !data.driverId ||
            !data.deviceId ||
            !data.isDriverDetails
          );
        case 2:
          return !data.insertedBatteryId || !data.insertedBatteryVoltage;
        case 3:
          return !data.takenBatteryId || !data.takenBatteryVoltage;
        default:
          break;
      }
    }
  };

  const handleSwapAgain = () => {
    handleReset();
  };

  useEffect(() => {
    updateData("swapType", swapType);
  }, [swapType]);

  const handleEnableBackButton = (index) => {
    if (activeStep >= index + 1) return true;
    else if (swapType === QUERYPARAMS.DIGITAL_TWIN) {
      if (activeStep === 3 && data?.batteryInsertionStartFromDigitalTwin)
        return true;
      else if (
        activeStep === 4 &&
        data?.batteryInsertionCompleteFromDigitalTwin
      )
        return true;
      else return false;
    }
  };
  const handleBackToSwapMenu = () => {
    navigate("/manual-swap");
  };

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      sx={{
        "& .MuiStepConnector-lineVertical": {
          ml: "8px",
        },
      }}
    >
      {steps &&
        steps.map((step, index) => (
          <Step
            key={step.label}
            expanded={step?.expanded && activeStep >= index}
            completed={step.completed}
          >
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon
                  {...props}
                  index={index}
                  active={index === activeStep}
                />
              )}
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent sx={{ ml: "20px" }}>
              {renderStepContent(step, index, data, updateData, swapType)}
              {activeStep === index && (
                <Box sx={{ mt: 2 }}>
                  {index !== steps.length - 1 ? (
                    <Button
                      variant="outlined"
                      disabled={handleEnableBackButton(index)}
                      onClick={index === 0 ? handleBackToSwapMenu : handleBack}
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>
                  ) : null}

                  {index === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      onClick={handleSwapAgain}
                      sx={{ my: 1 }}
                    >
                      SWAP AGAIN
                    </Button>
                  ) : index === steps.length - 2 ? (
                    <LoaderButton
                      variant="contained"
                      onClick={handleSubmit}
                      isLoading={isLoading}
                      disabled={
                        data.takenBatteryId === data.insertedBatteryId ||
                        handleNextButtonClick(index)
                      }
                      sx={{ width: "auto", minWidth: "150px" }}
                    >
                      COMPLETE SWAP
                    </LoaderButton>
                  ) : (
                    <AnimButton
                      onClick={handleStepCompletion}
                      disabled={handleNextButtonClick(index)}
                      index={index}
                    >
                      NEXT
                    </AnimButton>
                  )}
                </Box>
              )}
            </StepContent>
          </Step>
        ))}
    </Stepper>
  );
}
