import { useEffect } from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import AnimButton from "../mui/AniButton";
import Button from "@mui/material/Button";
import { PAYMENT_ENDPOINT, PAYMENT_TYPE } from "./constant";
import { Done } from "@mui/icons-material";
import Stepper from "@mui/material/Stepper";
import LoaderButton from "../mui/LoaderButton";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepContent from "@mui/material/StepContent";
import useCashCollection from "./hooks/use-cash-collection";
import { endpoints } from "../../utils/endpoints";

function CustomStepIcon(props) {
  const { active, completed, index } = props;
  return (
    <Box
      sx={{
        width: active ? 40 : 30,
        height: active ? 40 : 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: active || completed ? "#1DA7A7" : "grey.400",
        color: "#FFFFFF",
        borderRadius: "50%",
        ml: active ? "0px" : "5px",
      }}
    >
      {completed ? (
        <Done />
      ) : (
        <Typography variant={active ? "h6" : "body2"} sx={{ fontWeight: 600 }}>
          {index + 1}
        </Typography>
      )}
    </Box>
  );
}

export default function VerticalLinearStepper({
  renderStepContent,
  useStepperContext,
  onStepCompletion,
}) {
  const {
    data,
    activeStep,
    updateData,
    handleNext,
    handleBack,
    steps,
    setSteps,
  } = useStepperContext();

  const handleStepCompletion = () => {
    if (onStepCompletion && onStepCompletion[activeStep]) {
      onStepCompletion[activeStep](steps, setSteps);
    }
    handleNext();
  };

  const onSuccess = () => {
    if (onStepCompletion && onStepCompletion[activeStep]) {
      onStepCompletion[activeStep](steps, setSteps);
    }
    handleNext();
  };

  const { isLoading, isSuccess, errorMsg, onSubmit } =
    useCashCollection(onSuccess);

  const handleSubmit = () => {
    updateData("isInitCashCollection", true);
    let payload = { partnerId: data.partnerId };
    if (data.paymentType === PAYMENT_TYPE.WALLET_TOP_UP_AMOUNT) {
      payload.reasonForWalletTopUp = data.reasonForWalletTopUp;
      payload.amount = data.walletTopUpAmount;
    }
    if (
      data.paymentType === PAYMENT_TYPE.LAST_BILL_AMOUNT &&
      data.discount > 0
    ) {
      payload.discount = data.discount;
    }
    onSubmit(payload, PAYMENT_ENDPOINT[data.paymentType]);
  };

  useEffect(() => {
    if (data.isInitCashCollection) {
      if (isSuccess) updateData("isCompletedCashCollection", true);
      else {
        updateData("isCompletedCashCollection", false);
        updateData("errorMsg", errorMsg);
      }
    }
  }, [isSuccess, errorMsg, data.isInitCashCollection]);

  const handleNextButtonClick = (index) => {
    switch (index) {
      case 0:
        return !data.partnerId || !data.isPartnerDetails;
      case 1:
        return (
          !data.paymentType ||
          !data.isPaymentType ||
          (data.paymentType === PAYMENT_TYPE.WALLET_TOP_UP_AMOUNT &&
            !data.isMakeWalletTopUp) ||
          (data.paymentType === PAYMENT_TYPE.LAST_BILL_AMOUNT &&
            !data.isMakeLastBillPayment) ||
          data.paymentType === PAYMENT_TYPE.CURRENT_OUTSTANDING_AMOUNT
        );
      case 2:
        return false;
      default:
        break;
    }
  };

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      sx={{
        "& .MuiStepConnector-lineVertical": {
          ml: "8px",
        },
      }}
    >
      {steps &&
        steps.map((step, index) => (
          <Step
            key={step.label}
            expanded={step?.expanded && activeStep >= index}
            completed={step.completed}
          >
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon
                  {...props}
                  index={index}
                  active={index === activeStep}
                />
              )}
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent sx={{ ml: "20px" }}>
              {renderStepContent(index)}
              {activeStep === index && (
                <Box sx={{ mt: 2 }}>
                  {index !== steps.length - 1 ? (
                    <Button
                      variant="outlined"
                      disabled={
                        index === 0 ||
                        (index === 2 && data.isInitCashCollection)
                      }
                      onClick={handleBack}
                      sx={{ mr: 2 }}
                    >
                      Back
                    </Button>
                  ) : null}

                  {index === steps.length - 1 ? null : index ===
                    steps.length - 2 ? (
                    <>
                      {(data.paymentType === PAYMENT_TYPE.LAST_BILL_AMOUNT &&
                        data.partnerBillStatus?.billStatus === "paid") ||
                      (data.paymentType === PAYMENT_TYPE.LAST_BILL_AMOUNT &&
                        !data.partnerBillStatus) ? null : (
                        <LoaderButton
                          variant="contained"
                          onClick={handleSubmit}
                          isLoading={isLoading}
                          disabled={
                            data.paymentType ===
                            PAYMENT_TYPE.WALLET_TOP_UP_AMOUNT
                              ? !data.walletTopUpAmount
                              : handleNextButtonClick(index)
                          }
                          sx={{ width: "auto", minWidth: "150px" }}
                        >
                          CONFIRM PAYMENT
                        </LoaderButton>
                      )}
                    </>
                  ) : (
                    // <AnimButton
                    //   onClick={handleStepCompletion}
                    //   disabled={handleNextButtonClick(index)}
                    //   index={index}
                    // />
                    <LoaderButton
                      variant="contained"
                      onClick={handleStepCompletion}
                      disabled={handleNextButtonClick(index)}
                      sx={{ width: "auto", height: "37px", minWidth: "90px" }}
                      isLoading={data.isPartnerBillStatusLoading && index === 1}
                    >
                      NEXT
                    </LoaderButton>
                  )}
                </Box>
              )}
            </StepContent>
          </Step>
        ))}
    </Stepper>
  );
}
