import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import openLockSvg from "../assets/icons/lock-open.svg";
import lockSvg from "../assets/icons/lock.svg";

export default function DoorWidgetView({
  data,
  index,
  selectedDoor,
  onClick,
  disabled,
  sx,
}) {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "center",
        p: 2,
        gap: 1,
        width: "100%",
        cursor: disabled ? "not-allowed" : "pointer",
        transition: "transform 0.3s ease-in-out",
        border: `2px solid ${
          selectedDoor === index
            ? "#1DA7A7"
            : data.canIds[index]
            ? "#ACE2E1"
            : "#D2E0FB"
        }`,
        borderRadius: "8px",
        boxShadow: "none",
        bgcolor: `${
          selectedDoor === index
            ? "#FFFFFF"
            : data.canIds[index]
            ? "#CDE8E5"
            : "#EEF5FF"
        }`,
        opacity: disabled ? 0.5 : 1,
        ...sx,
      }}
      onClick={() => {
        if (!disabled) {
          onClick(index, data.canIds[index]);
        }
      }}
    >
      <Typography variant="body2">Door: {index + 1}</Typography>
      <Box>
        <Typography variant="body2">{data.canIds[index] || "Empty"}</Typography>
        <Typography sx={{ fontSize: "14px" }}>
          <span>{data.voltage[index] ? `${data.voltage[index]}V` : "NA"}</span>
          <span> | </span>
          <span>{data.soc[index] ? `${data.soc[index]}%` : "NA"}</span>
        </Typography>
      </Box>
      {data.doorStatus[index] === 1 ? (
        <img style={{ width: "30px" }} src={openLockSvg} alt="Lock Icon" />
      ) : (
        <img style={{ width: "30px" }} src={lockSvg} alt="Open Lock Icon" />
      )}
    </Card>
  );
}
