import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { Box, Grid } from "@mui/material";
import { CircularProgress } from "../../mui";
import { useStepperContext } from "../StepperContext";
import { endpoints } from "../../../utils/endpoints";
import { db, doc, onSnapshot, Timestamp } from "../../../firebase";
import {
  DeviceStatusDialog,
  DoorStatusAndActionButton,
  DoorWidgetView,
  StationSyncStatus,
  ViewCards,
} from ".";
import {
  ACTION_TYPE,
  DIGITAL_TWIN_ACTION,
  SKIP_BATTERY_DETECTION_REASON,
} from "../common";

const DigitalTwin = ({
  index,
  action,
  batteryIdKey,
  batterySocKey,
  swapActionStartKey,
  swapActionCompleteKey,
  batteryDetailsViewKey,
  batteryDoorCloseConfirmKey,
}) => {
  const [soc, setSoc] = useState([]);
  const [canIds, setCanIds] = useState([]);
  const [voltage, setVoltage] = useState([]);
  const [doorStatus, setDoorStatus] = useState([]);
  const [isDeviceData, setIsDeviceData] = useState(null);
  const [selectedDoor, setSelectedDoor] = useState(null);
  const [syncTimestamp, setSyncTimestamp] = useState(null);
  const [status, setStatus] = useState({
    isOnline: false,
    isDoorOpen: false,
    isDoneDoorClose: false,
    isCompleted: false,
    isInitDoorOpen: false,
    initDoorNumber: null,
    isDoorOpenMarkedClose: false,
    isBatteryDetected: false,
    isTimerCompleted1: false,
    isTimerCompleted2: false,
    isTimerCompleted3: false,
  });

  const { data, activeStep, updateData } = useStepperContext();
  const offlineTimeoutRef = useRef(null);
  const lastUpdateTimeRef = useRef(Date.now());

  const isDoorOpen = (status) => status === 1;

  const handleCheckSelectedDoorStatus = (doorNo) => {
    updateData(batteryIdKey, canIds[doorNo]);
    updateData(batterySocKey, soc[doorNo]);
    updateData("batteryTakenFromDoor", doorNo);
  };

  const handleSelectedDoor = (doorNo) => {
    setSelectedDoor(doorNo);
    handleInitDoorStatus(doorNo);
    if (action === DIGITAL_TWIN_ACTION.OUT_BATTERY)
      handleCheckSelectedDoorStatus(doorNo);
  };

  const handleInitDoorStatus = (doorNo) => {
    const selectedDoorStatus = doorStatus[doorNo];
    setStatus((prev) => ({
      ...prev,
      isDoorOpen: isDoorOpen(selectedDoorStatus),
    }));
  };

  const handleDoorStatusUpdate = (doorStatuses, selectedDoor, prevStatus) => {
    if (!doorStatuses || selectedDoor === null) {
      return prevStatus;
    }

    const doorStatus = doorStatuses[selectedDoor];

    const updatedStatus = {
      ...prevStatus,
      isDoorOpen: isDoorOpen(doorStatus),
    };

    if (
      prevStatus.isInitDoorOpen &&
      prevStatus.isDoorOpen &&
      !isDoorOpen(doorStatus)
    ) {
      updatedStatus.isDoneDoorClose = true;
      updateData(batteryDoorCloseConfirmKey, ACTION_TYPE.AUTO);
      handleCompleteSwap();
    }

    return updatedStatus;
  };

  useEffect(() => {
    setStatus((prevStatus) =>
      handleDoorStatusUpdate(doorStatus, selectedDoor, prevStatus)
    );

    if (status.isDoorOpen && status.isInitDoorOpen) {
      setStatus((prev) => ({
        ...prev,
        isTimerCompleted1: true,
      }));
    }
  }, [selectedDoor, doorStatus]);

  const handleActionComplete = () => {
    updateData(swapActionCompleteKey, true);
  };

  const handleCompleteSwap = () => {
    if (
      action === DIGITAL_TWIN_ACTION.OUT_BATTERY ||
      data.swapReason === SKIP_BATTERY_DETECTION_REASON
    ) {
      setStatus((prev) => ({
        ...prev,
        isCompleted: true,
        isTimerCompleted3: true,
        isBatteryDetected: true,
      }));
      handleActionComplete();
    }

    if (action === DIGITAL_TWIN_ACTION.IN_BATTERY)
      updateData("batteryInsertedIntoDoor", selectedDoor);

    if (action === DIGITAL_TWIN_ACTION.IN_BATTERY && canIds[selectedDoor]) {
      handleActionComplete();
    }
  };

  useEffect(() => {
    if (
      selectedDoor === status.initDoorNumber &&
      action === DIGITAL_TWIN_ACTION.IN_BATTERY &&
      canIds[selectedDoor]
    ) {
      updateData(
        batteryIdKey,
        canIds[selectedDoor] || data.insertedBatteryIdDT
      );
      updateData(batterySocKey, soc[selectedDoor] || data.insertedBatterySocDT);
      updateData("batteryInsertedDetectionConf", ACTION_TYPE.AUTO);
      updateData("batteryInsertedIntoDoor", selectedDoor);
      setStatus((prev) => ({
        ...prev,
        isTimerCompleted3: true,
        isBatteryDetected: true,
      }));
      if (status.isDoneDoorClose) handleActionComplete();
    }
  }, [selectedDoor, action, canIds, soc, status.initDoorNumber]);

  const handleMarkedDoorClose = () => {
    updateData(batteryDoorCloseConfirmKey, ACTION_TYPE.MANUAL);
    setStatus((prev) => ({
      ...prev,
      isDoorOpenMarkedClose: true,
      isDoneDoorClose: true,
      isDoorOpen: false,
    }));
    handleCompleteSwap();
  };
  const handleConfirmBatteryDetectionManual = () => {
    setStatus((prev) => ({ ...prev, isCompleted: true }));
    updateData("batteryInsertedDetectionConf", ACTION_TYPE.MANUAL);
    updateData("batteryInsertedIntoDoor", selectedDoor);
    if (action === DIGITAL_TWIN_ACTION.IN_BATTERY) updateData(batterySocKey, 0);
    handleActionComplete();
  };

  const handleTimerCompleted1 = () => {
    setStatus((prev) => ({ ...prev, isTimerCompleted1: true }));
  };

  const handleTimerCompleted2 = () => {
    setStatus((prev) => ({ ...prev, isTimerCompleted2: true }));
  };

  const handleTimerCompleted3 = () => {
    setStatus((prev) => ({ ...prev, isTimerCompleted3: true }));
  };

  useEffect(() => {
    const deviceId = data.deviceId;
    // const deviceId = "869640058240626";
    const documentRef = doc(db, "devices", deviceId, "SwapStn", "Status");
    const unsubscribe = onSnapshot(documentRef, (documentSnapshot) => {
      if (documentSnapshot.exists()) {
        const deviceObj = documentSnapshot.data().state;
        setIsDeviceData(true);
        setCanIds(deviceObj.canId);
        setDoorStatus(deviceObj.door_status_1);
        setVoltage(deviceObj.voltage);
        setSoc(deviceObj.soc);

        if (deviceObj?.timeStamp) {
          const { seconds, nanoseconds } = deviceObj.timeStamp;
          const firestoreTimestamp = new Timestamp(seconds, nanoseconds);
          const date = firestoreTimestamp.toDate();
          const formattedDate = format(date, "hh:mm:ss a dd/MM/yyyy");
          setSyncTimestamp(formattedDate);
          // console.log("firestoreTimestamp: ", date);

          // Update the last update time
          lastUpdateTimeRef.current = Date.now();

          // Set status to online immediately when we receive an update
          // setStatus((prev) => ({ ...prev, isOnline: timeDifference <= 20 }));
          setStatus((prev) => ({ ...prev, isOnline: true }));

          // Clear any existing timeout
          if (offlineTimeoutRef.current) {
            clearTimeout(offlineTimeoutRef.current);
          }

          offlineTimeoutRef.current = setTimeout(() => {
            setStatus((prev) => ({ ...prev, isOnline: false }));
          }, 21000); // 21 seconds to give a small buffer
        }

        setStatus((prevStatus) =>
          handleDoorStatusUpdate(
            deviceObj.door_status_1,
            selectedDoor,
            prevStatus
          )
        );
      } else {
        console.log("Document not found");
        setStatus((prev) => ({ ...prev, isOnline: false }));
      }
    });

    return () => {
      unsubscribe();
      if (offlineTimeoutRef.current) {
        clearTimeout(offlineTimeoutRef.current);
      }
    };
  }, [data.deviceId, selectedDoor]);

  const handleOpenDoorCommand = async () => {
    setStatus((prev) => ({
      ...prev,
      isTimerCompleted1: false,
    }));
    // const deviceId = "869640058240626" || data.deviceId;
    const deviceId = "869640058240626";
    if (deviceId) {
      const payload = { doorId: selectedDoor, deviceId };
      setStatus((prev) => ({
        ...prev,
        isInitDoorOpen: true,
        initDoorNumber: selectedDoor,
      }));
      updateData(swapActionStartKey, true);
      try {
        const res = await axios.post(endpoints.openDoorCommand.post, payload);
        console.log("Response:", res.data);
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const handleDoorEnabledDisabled = (index) => {
    if (action === DIGITAL_TWIN_ACTION.OUT_BATTERY) {
      return (
        !canIds[index] ||
        canIds[index] === "" ||
        data.insertedBatteryIdDT === canIds[index] ||
        (status.isInitDoorOpen && status.initDoorNumber !== index)
      );
    } else {
      return status.isInitDoorOpen && status.initDoorNumber !== index;
    }
  };

  return (
    <Box>
      {activeStep > index ? (
        <ViewCards view={batteryDetailsViewKey} />
      ) : !isDeviceData ? (
        <CircularProgress />
      ) : (
        <>
          <Box
            sx={{
              border: "1px solid #0C0C0C5C",
              borderRadius: 3,
              p: { xs: 2, sm: 3, md: 4, lg: 5 },
            }}
          >
            <StationSyncStatus
              syncTimestamp={syncTimestamp}
              isOnline={status.isOnline}
              stationIMEI={data.deviceId}
              partnerId={data.partnerId}
            />

            <Grid container spacing={{ xs: 2, md: 3 }}>
              {new Array(8).fill(0).map((_, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <DoorWidgetView
                    data={{ canIds, doorStatus, soc, voltage }}
                    index={index}
                    selectedDoor={selectedDoor}
                    onClick={handleSelectedDoor}
                    disabled={handleDoorEnabledDisabled(index)}
                  />
                </Grid>
              ))}
            </Grid>

            <DoorStatusAndActionButton
              status={status}
              action={action}
              selectedDoor={selectedDoor}
              handleOpenDoorCommand={handleOpenDoorCommand}
              handleTimerCompleted1={handleTimerCompleted1}
              handleTimerCompleted2={handleTimerCompleted2}
              handleTimerCompleted3={handleTimerCompleted3}
              handleMarkedDoorClose={handleMarkedDoorClose}
              handleConfirmBatteryDetectionManual={
                handleConfirmBatteryDetectionManual
              }
            />
          </Box>
          <DeviceStatusDialog
            open={!status.isOnline}
            syncTimestamp={syncTimestamp}
          />
        </>
      )}
    </Box>
  );
};

export default DigitalTwin;
