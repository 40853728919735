import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { CircularProgress, Typography, useMediaQuery } from "@mui/material";
import Highlighter from "react-highlight-words";
import { FixedSizeList as List } from "react-window";

// const customStyles = {
//   loadingIndicator: (base) => ({
//     ...base,
//     color: "#1DA7A7",
//     borderColor: error ? 'red' : base.borderColor,
//     '&:hover': {
//       borderColor: error ? 'red' : base.borderColor,
//     },
//   }),
// };

const MenuList = (props) => {
  const height = 45;
  const { options, children, maxHeight, getValue, isLoading, selectProps } =
    props;
  const [value] = getValue();
  const { isVisualization } = selectProps;
  const initialOffset = options.indexOf(value) * height;
  const isMobile = useMediaQuery("(max-width:600px)");

  let newMaxHeight = maxHeight;

  if (350 > children.length * height) newMaxHeight = children.length * height;

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", padding: "10px" }}>
        <CircularProgress size={24} />
      </div>
    );
  }
  if (isMobile && !isVisualization) {
    return <components.MenuList {...props} />;
  }

  return (
    <List
      height={newMaxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      width="100%"
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const Option = (props) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { label } = props.data;
  const { inputValue, isHighlighter, highlightStyle } = props.selectProps;

  if (typeof label !== "string") return label;

  const formatLabel = (labelText) => (
    <Highlighter
      searchWords={[inputValue]}
      textToHighlight={labelText}
      highlightStyle={
        isHighlighter
          ? {
              fontWeight: "bold",
              padding: 0,
              backgroundColor: "transparent",
              ...highlightStyle,
            }
          : {}
      }
    />
  );

  if (isMobile) {
    const parts = label.split("/");
    return (
      <components.Option {...props}>
        <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span style={{ color: "#999" }}>/</span>}
              <span style={{ display: "inline-block" }}>
                {isHighlighter ? formatLabel(part) : part}
              </span>
            </React.Fragment>
          ))}
        </div>
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      {isHighlighter ? formatLabel(label) : label}
    </components.Option>
  );
};
const RCSelect = ({
  label,
  value,
  error,
  options,
  onChange,
  isLoading = false,
  isDisabled = false,
  styles = {},
  placeholder,
  isHighlighter = true,
  highlightStyle,
  isClearable = true,
  isSearchable = true,
  isVisualization = false,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: error ? "red" : base.borderColor,
      "&:hover": {
        borderColor: error ? "red" : base.borderColor,
      },
    }),
    loadingIndicator: (base) => ({
      ...base,
      color: "#1DA7A7",
    }),
  };

  return (
    <>
      {label && (
        <Typography variant="body2" sx={{ mb: "4px" }}>
          {label}
        </Typography>
      )}

      <Select
        key={windowWidth}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        value={value}
        onChange={onChange}
        isLoading={isLoading}
        styles={{ ...customStyles, ...styles }}
        isDisabled={isDisabled}
        components={{ MenuList, Option }}
        isHighlighter={isHighlighter}
        highlightStyle={highlightStyle}
        isVisualization={isVisualization}
      />

      {error && (
        <Typography variant="body2" color="error" sx={{ my: 2 }}>
          {error}
        </Typography>
      )}
    </>
  );
};

export default RCSelect;
