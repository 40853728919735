import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { CircularProgress, RCSelect } from "../../mui";
import ViewCards from "../components/ViewCards";
import { useQuery } from "@tanstack/react-query";
import { useStepperContext } from "../StepperContext";
import { getPartnerDetails, getPartnerIds } from "../../../hooks/use-partner";

const StepOne = ({ index }) => {
  const { data, activeStep, updateData } = useStepperContext();
  const [partnerIdList, setPartnerIdList] = useState([]);

  const { data: partnerIdListData = [], isLoading: isPartnerLoading } =
    useQuery({
      queryKey: ["partnerIds"],
      queryFn: getPartnerIds,
    });

  const {
    data: partnerDetails,
    isLoading: isPartnerDetailsLoading,
    refetch: refetchPartnerDetails,
  } = useQuery({
    queryKey: ["partnerDetails", data.partnerId],
    queryFn: () => getPartnerDetails(data.partnerId),
    enabled: !!data.partnerId,
  });

  useEffect(() => {
    if (data.partnerId) refetchPartnerDetails();
  }, [data.partnerId]);

  useEffect(() => {
    updateData("isPartnerDetails", false);
    if (partnerDetails) {
      updateData("partnerDetails", partnerDetails);
      updateData("isPartnerDetails", true);
    }
  }, [partnerDetails]);

  useEffect(() => {
    if (partnerIdListData && Array.isArray(partnerIdListData)) {
      const filterData = partnerIdListData.filter(
        (item) => item && item.partnerId
      );
      setPartnerIdList(
        filterData.map((item) => ({
          value: item.partnerId,
          label: item.partnerId,
        }))
      );
    }
  }, [partnerIdListData]);

  return (
    <Box>
      <RCSelect
        label="Enter Partner ID"
        placeholder="Enter Partner ID"
        options={partnerIdList}
        value={
          partnerIdList.find((option) => option.value === data.partnerId) || ""
        }
        onChange={(selectedOption) => {
          updateData("isPartnerDetails", false);
          updateData("partnerId", selectedOption?.value || null);
        }}
        isLoading={isPartnerLoading}
        isDisabled={activeStep > index}
      />
      {isPartnerDetailsLoading ? (
        <CircularProgress />
      ) : (
        data.partnerId && partnerDetails && <ViewCards view="partnerDetails" />
      )}
    </Box>
  );
};

export default StepOne;
