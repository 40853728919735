import { Box, Button, Typography } from "@mui/material";
import Services from "./services/Services";
import PageLayout from "../PageLayout";

const HomePage = ({ signOut, user }) => {
  return (
    <PageLayout sx={{ p: { xs: 2, sm: 2, md: 5 } }}>
      <Services />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 8,
          alignItems: "center",
          gap: 2,
          flexDirection: { xs: "column", sm: "row", md: "row" },
        }}
      >
        <Typography variant="body1">{user.email}</Typography>
        <Button variant="outlined" onClick={signOut}>
          Sign out
        </Button>
      </Box>
    </PageLayout>
  );
};

export default HomePage;
