import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: "flex", my: 3, alignItem: "center" }}>
      <CircularProgress sx={{ width: 4 }} />
    </Box>
  );
}
