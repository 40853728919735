import React, { createContext, useContext, useState } from "react";

const StepperContext = createContext();

export const useStepperContext = () => {
  return useContext(StepperContext);
};

export const StepperProvider = ({ children, initialSteps }) => {
  const [data, setData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(initialSteps);

  const updateData = (key, value) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setData({});
  };

  return (
    <StepperContext.Provider
      value={{
        data,
        steps,
        setSteps,
        activeStep,
        updateData,
        handleNext,
        handleBack,
        handleReset,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
