import { Stack, TextField } from "@mui/material";
import Select from "react-select";
import { useStepperContext } from "../../StepperContext";
import { QUERYPARAMS, reasonOptions } from "../constant";
import { useEffect, useState } from "react";

const StepOne = () => {
  const { data, updateData } = useStepperContext();

  const [swapReasons, setSwapReasons] = useState([]);

  useEffect(() => {
    if (data.swapType === QUERYPARAMS.DIGITAL_TWIN)
      setSwapReasons(reasonOptions.digitalTwin);
    else setSwapReasons(reasonOptions.inventory);
  }, [data.swapType]);

  return (
    <Stack gap={3}>
      <Select
        isClearable
        isSearchable={false}
        options={swapReasons}
        value={swapReasons.find((option) => option.value === data.swapReason)}
        onChange={(selectedOption) => {
          if (selectedOption?.value === "Other") {
            updateData("swapReason", "Other");
            updateData("customReason", "");
          } else {
            updateData("swapReason", selectedOption?.value || "");
            updateData("customReason", null);
          }
        }}
      />
      {data.swapReason === "Other" && (
        <TextField
          fullWidth
          size="small"
          value={data.customReason || ""}
          onChange={(e) => updateData("customReason", e.target.value)}
        />
      )}
    </Stack>
  );
};

export default StepOne;
