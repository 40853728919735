import React from "react";
import { Button } from "@mui/material";
import { keyframes } from "@emotion/react";

const ring = keyframes`
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 120px;
    height: 120px;
    opacity: 0;
  }
`;

const AnimButton = ({ children, onClick, disabled }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        position: "relative",
        "&:after": {
          content: '""',
          borderRadius: "100%",
          border: "7px solid #00FFCB",
          position: "absolute",
          zIndex: -1,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          animation: disabled ? "none" : `${ring} 1.5s infinite`,
          display: disabled ? "none" : "block",
        },
        "&:hover:after, &:focus:after": {
          animation: "none",
          display: "none",
        },
      }}
    >
      {children}
    </Button>
  );
};

export default AnimButton;
