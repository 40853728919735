import { createTheme } from "@mui/material/styles";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1DA7A7",
      dark: "#115293",
    },
    secondary: {
      main: "#1A2130",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          border: "1px solid #1DA7A7",
          backgroundColor: "#1DA7A7",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#1A2130",
            border: "1px solid #1A2130",
            color: "#FFFFFF",
          },
          "&.Mui-disabled": {
            border: "1px solid #D8D9DA",
            backgroundColor: "#D8D9DA",
            color: "#1A2130",
            opacity: 0.7,
          },
        },
        containedSecondary: {
          border: "1px solid #1DA7A7",
          backgroundColor: "#1DA7A7",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#1A2130",
            border: "1px solid #1A2130",
            color: "#FFFFFF",
          },
          // "&:active": {
          //   backgroundColor: "#1A2130",
          //   border: "1px solid #1A2130",
          //   color: "#FFFFFF",
          // },
          "&.Mui-disabled": {
            border: "1px solid #EFEFEF",
            backgroundColor: "rgba(239, 239, 239, 0.5)",
            color: "#767676",
            opacity: 0.5,
          },
        },
      },
    },
  },
});

export default theme;
