import React from "react";
import { LastStep, StepOne, StepThree, StepTwo } from "./steps";

export const renderStepContent = (index) => {
  switch (index) {
    case 0:
      return <StepOne index={index} />;
    case 1:
      return <StepTwo index={index} />;
    case 2:
      return <StepThree index={index} />;
    case 3:
      return <LastStep />;
    default:
      return "Unknown step";
  }
};
