import { Box, Stack, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getBatteryIds } from "../../../hooks/use-battery";
import { useStepperContext } from "../StepperContext";
import { BRCSelect, ViewCards } from "../components";

const StepFour = ({ index }) => {
  const { data, activeStep, updateData } = useStepperContext();

  const { data: batteryIdList, isLoading: isBatteryLoading } = useQuery({
    queryKey: ["batteryIds"],
    queryFn: () => getBatteryIds(data.driverId),
  });

  const batteryOptions = batteryIdList?.allBatteries?.map((item) => ({
    label: item,
    value: item,
  }));

  return (
    <>
      <Stack gap={3}>
        {activeStep > index ? (
          <ViewCards view="outBatteryDetails" />
        ) : (
          <>
            <BRCSelect
              label="Select taken battery ID:"
              options={batteryOptions}
              value={batteryOptions.find(
                (option) => option.value === data.takenBatteryId
              )}
              onChange={(selectedOption) => {
                updateData("takenBatteryId", selectedOption?.value || "");
              }}
              isLoading={isBatteryLoading}
            />
            <Box>
              <Typography variant="body2">
                Enter taken battery voltage:
              </Typography>
              <TextField
                fullWidth
                type="number"
                size="small"
                placeholder="Battery Voltage"
                value={data.takenBatteryVoltage || ""}
                onChange={(e) =>
                  updateData("takenBatteryVoltage", e.target.value)
                }
                sx={{ mt: 0.5 }}
              />
            </Box>
          </>
        )}
      </Stack>
      {data.takenBatteryId === data.insertedBatteryId && (
        <Typography variant="body2" color="error" mt={1}>
          Both battery ids are same!
        </Typography>
      )}

      {data?.swapErrorRes && (
        <Typography variant="body2" color="error" mt={1}>
          {`${data.swapErrorRes.code}: ${data.swapErrorRes.message}`}
        </Typography>
      )}
    </>
  );
};

export default StepFour;
